import React, { FC, ReactNode, useContext, useState } from 'react';
import ModalDialog from '@leuven2030/framework/Modal/ModalDialog';
import 'twin.macro';
import { NavigationLinkProps } from '@leuven2030/framework/Navigation/NavigationLink';
import { NavigationContext } from '@leuven2030/framework/Navigation/NavigationProvider';

const NavigationLinkModal: FC<NavigationLinkProps> = ({
  children,
  prismicMeta: { uid },
  ...rest
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { modals } = useContext(NavigationContext);
  function getModalContent(): ReactNode {
    const modal = modals.find((modal) => modal.uid == uid);
    if (modal) {
      return <modal.Component />;
    }
    return <div>Could not find modal with uid: {uid}</div>;
  }

  return (
    <>
      <a
        onClick={() => {
          setModalOpen(true);
        }}
        {...rest}
      >
        {children}
      </a>
      <ModalDialog
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        {getModalContent()}
      </ModalDialog>
    </>
  );
};

export default NavigationLinkModal;
