import React, { FC, useContext } from 'react';
import Button from '@leuven2030/framework/Button/Button';
import FormSubmit from '@leuven2030/framework/Form/FormSubmit';
import { FormStepContext } from '@leuven2030/framework/Form/FormStepProvider';
import { FormStepsContext } from '@leuven2030/framework/Form/FormStepsContainer';
import FieldPrismic from '@leuven2030/framework/Field/FieldPrismic';
import FormSubmitErrors from '@leuven2030/framework/Form/FormSubmitErrors';
import FocusLock from 'react-focus-lock';
import tw from 'twin.macro';

const FormStepContent: FC = () => {
  const { previousStep, nextStep, items, size } = useContext(FormStepsContext);
  const { index, description, fields } = useContext(FormStepContext);
  const isLastItem = items.length === index + 1;
  return (
    <div
      tw="flex flex-col space-y-6"
      css={[size === 'default' && tw`sm:(flex-row space-x-12 space-y-0)`]}
    >
      <div tw="flex-1">
        <p tw="text-gray-500">{description}</p>
      </div>
      <div>
        <FocusLock
          tw="max-w-sm space-y-6 "
          css={[size === 'default' && tw`sm:(w-1/2) md:(w-full)`]}
        >
          {fields.map((field, index) => (
            <FieldPrismic key={index} data={field} />
          ))}
          {isLastItem && <FormSubmitErrors />}
          <div tw="flex justify-end w-full space-x-3">
            {!!index && (
              <Button variant="contained" onClick={previousStep}>
                Vorige
              </Button>
            )}
            {!!items.length && !isLastItem && (
              <Button variant="contained" color="primary" onClick={nextStep}>
                Volgende
              </Button>
            )}
            {isLastItem && <FormSubmit />}
          </div>
        </FocusLock>
      </div>
    </div>
  );
};

export default FormStepContent;
