import Button from '@leuven2030/framework/Button/Button';
import { MenuContext } from '@leuven2030/framework/Menu/Menu';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import React, { FC, useContext } from 'react';
import tw from 'twin.macro';

type Props = {
  label: string;
};
const NavigationMenuButton: FC<Props> = ({ label, children, ...rest }) => {
  const { handleClick, open } = useContext(MenuContext);
  return (
    <Button
      tw="text-primary-600 text-xs sm:text-sm rounded-none border-b-2 border-transparent border-solid dark:(text-white)"
      css={[open && tw` border-primary-500 dark:(border-gray-200)`]}
      color="primary"
      onClick={handleClick}
      endIcon={<KeyboardArrowDown />}
      {...rest}
    >
      {label}
    </Button>
  );
};

export default NavigationMenuButton;
