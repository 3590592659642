import React, { useContext } from 'react';
import 'twin.macro';
import { FormContext } from '@leuven2030/framework/Form/Form';
import { useFormikContext } from 'formik';
import ErrorApollo from '@leuven2030/framework/Error/ErrorApollo';

const FormSubmitErrors = () => {
  const { error, setError } = useContext(FormContext);
  const { isSubmitting } = useFormikContext();

  return (
    <ErrorApollo
      error={error}
      hidden={isSubmitting}
      onClose={() => {
        setError(null);
      }}
    />
  );
};

export default FormSubmitErrors;
