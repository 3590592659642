import React, { useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import StateNotFound from '@leuven2030/framework/State/StateNotFound';
import PrismicText from '@leuven2030/framework/Prismic/PrismicText';
import { connectHits } from 'react-instantsearch-dom';
import 'twin.macro';
import SearchHit from '@leuven2030/framework/Search/SearchHit';
import { SearchContext } from '@leuven2030/framework/Search/SearchProvider';
const Hits = ({ hits }) => {
  const {
    prismic: {
      search_config: {
        empty_state: { title, text }
      }
    }
  } = useContext(PageContext);
  const {
    selectedGroup: { state: groupState }
  } = useContext(SearchContext);
  if (groupState) {
    return <></>;
  }
  if (!hits.length) {
    return (
      <div>
        <StateNotFound
          title={title}
          description={<PrismicText text={text} />}
        />
      </div>
    );
  }
  return (
    <div tw="grid lg:grid-cols-2 ">
      {hits.map((hit, index) => {
        return <SearchHit hit={hit} key={hit.objectID} />;
      })}
    </div>
  );
};

const SearchHits = connectHits(Hits);

export default SearchHits;
