import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import React, { FC } from 'react';
import Button from '@leuven2030/framework/Button/Button';
import 'twin.macro';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { useFormikContext } from 'formik';
const RecaptchaButton: FC<{ recaptchaAction: string } & ButtonProps> = ({
  recaptchaAction,
  onClick,
  ...props
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { setFieldValue } = useFormikContext();

  const clickHandler = async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha(recaptchaAction);
    setFieldValue('recaptcha', token);
    onClick && onClick(e);
  };

  return (
    <Button
      onClick={clickHandler}
      variant="contained"
      color="primary"
      {...props}
    />
  );
};

export default RecaptchaButton;
