import State, { StateProps } from '@leuven2030/framework/State/State';
import { FC } from 'react';

const StateAllDone: FC<StateProps> = (props) => {
  return (
    <State
      filename="b83aa546-f8be-4424-85e3-b67fd05a9ec5_state+-+all+done.png"
      {...props}
    />
  );
};

export default StateAllDone;
