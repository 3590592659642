import React, { createContext, FC, useEffect, useState } from 'react';
import PrismicGraphql from '@leuven2030/framework/Prismic/PrismicGraphql';
import WidgetQuery from '@leuven2030/framework/Widget/WidgetQuery';
export const WidgetPrismicContext = createContext({
  data: null
});

const WidgetPrismic: FC<{
  loadContent?: () => Promise<any>;
  data?: any;
}> = ({ children, loadContent, data: initialData }) => {
  const [data, setData] = useState<any>(initialData);
  useEffect(() => {
    async function init() {
      loadContent && setData(await loadContent());
    }
    init().catch(console.error);
  }, []);

  return (
    <WidgetPrismicContext.Provider value={{ data }}>
      {children}
    </WidgetPrismicContext.Provider>
  );
};

export default WidgetPrismic;
