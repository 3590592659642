import { FC } from 'react';
import { PopperPlacementType } from '@material-ui/core/Popper';
import tw from 'twin.macro';
type Props = {
  setArrowRef;
  color?: string;
  placement: PopperPlacementType;
};

const MenuArrow: FC<Props> = ({ setArrowRef, placement, color = '#fff' }) => {
  function getPlacementStyle() {
    if (placement == 'bottom') {
      return {
        top: 0
      };
    }
    if (placement == 'top') {
      return {
        bottom: 0
      };
    }
  }
  return (
    <div
      tw="z-10  w-4 h-4 absolute transform"
      ref={setArrowRef}
      css={[
        placement == 'bottom' && tw`top-0 -mt-2`,
        placement == 'top' && tw`bottom-0 -mb-2 rotate-180`
      ]}
    >
      <div
        tw="w-0 h-0"
        css={`
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid ${color};
        `}
        style={getPlacementStyle()}
      />
    </div>
  );
};

export default MenuArrow;
