import Button from '@leuven2030/framework/Button/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from 'next/link';
import React, { useContext } from 'react';
import 'twin.macro';
import { PageContext } from '@leuven2030/framework/Page/Page';
const PageLink = ({ _meta, title, ...props }) => {
  const { linkResolver } = useContext(PageContext);
  return (
    <Link href={linkResolver(_meta)}>
      <Button color="primary" endIcon={<NavigateNextIcon />} {...props}>
        {title}
      </Button>
    </Link>
  );
};

export default PageLink;
