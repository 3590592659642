import React, { FC, useContext } from 'react';
import { useField, useFormikContext } from 'formik';
import tw from 'twin.macro';
import FieldContainerBase from '@leuven2030/framework/Field/FieldContainerBase';
import { FieldContext } from '@leuven2030/framework/Field/FieldProvider';

import isBoolean from 'lodash/isBoolean';
const FieldContainer: FC<{ className?: string }> = (props) => {
  const { name } = useContext(FieldContext);
  const [field, meta] = useField(name);
  const { isSubmitting } = useFormikContext();
  const state = getFieldState({ field, meta });
  return (
    <FieldContainerBase
      className={state.error ? 'field-error' : null}
      css={[isSubmitting && tw`pointer-events-none`]}
      {...props}
      {...state}
    />
  );
};

export type FieldState = {
  touched: boolean;
  error: string;
  isValid: boolean;
};

export function getFieldState({ field, meta }): FieldState {
  const valueChanged = isBoolean(field.value)
    ? true
    : meta.initialValue !== field.value;
  const touched = meta.touched || valueChanged;
  const error = meta?.error;
  const isValid = touched && !error;

  return {
    touched,
    error,
    isValid
  };
}
export default FieldContainer;
