import React, { useContext, useEffect } from 'react';
import 'twin.macro';
import { MenuMobileContext } from '@leuven2030/framework/Menu/MenuMobileProvider';
import MenuMobilePortal from '@leuven2030/framework/Menu/MenuMobilePortal';
const MenuMobile = ({ onClose: onCloseProps, open: openProps, ...props }) => {
  const { onOpen, open, onClose } = useContext(MenuMobileContext);
  useEffect(() => {
    openProps && onOpen();
    !openProps && onClose();
  }, [openProps]);
  useEffect(() => {
    !open && onCloseProps();
  }, [open]);
  return <MenuMobilePortal {...props} />;
};

export default MenuMobile;
