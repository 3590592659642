import GroupSelect from '@leuven2030/framework/Group/GroupSelect';
import 'twin.macro';
import { FC } from 'react';
import FormFieldAutocomplete, {
  FormFieldAutocompleteProps
} from '@leuven2030/framework/Form/FormFieldAutocomplete';

const GroupSelectFormField: FC<FormFieldAutocompleteProps> = (props) => {
  return <FormFieldAutocomplete {...props} Component={GroupSelect} />;
};

export default GroupSelectFormField;
