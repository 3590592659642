import { Alert } from '@material-ui/lab';
import React, { FC, useEffect, useState } from 'react';
import map from 'lodash/map';
import 'twin.macro';
import Collapse from '@material-ui/core/Collapse';

const ErrorApollo: FC<{
  onClose?: () => void;
  error: any;
  hidden?: boolean;
}> = ({ onClose, error, hidden = false }) => {
  const [errorHidden, setErrorHidden] = useState(true);
  useEffect(() => {
    if (error) {
      setErrorHidden(false);
    }
  }, [error]);

  function getErrorContent() {
    if (!error) {
      return;
    }
    if (error.networkError) {
      return getNetworkError();
    }
    if (error.graphQLErrors) {
      return getGraphqlErrors();
    }
    return error.message;
  }

  function getNetworkError() {
    return <p>{error.networkError.message}</p>;
  }
  function getGraphqlErrors() {
    const graphQLErrors = error.graphQLErrors.map(onGraphqlError);

    function onGraphqlError(graphqlError, graphqlErrorIndex) {
      const validationErrorsData =
        graphqlError?.extensions?.exception?.validationErrors || [];

      const validationErrors = map(validationErrorsData, onValidationError);
      return (
        <div key={graphqlErrorIndex}>
          <p tw=" mb-3 leading-none">{graphqlError.message}</p>
          <div tw="space-y-2">{validationErrors}</div>
        </div>
      );
    }

    function onValidationError(validationError, validationErrorIndex) {
      const constraints = map(validationError.constraints, onConstraint);

      return (
        <div key={validationErrorIndex}>
          <p tw="font-bold">{validationError.property}:</p>
          <div>{constraints}</div>
        </div>
      );
    }

    function onConstraint(message, key) {
      return <p key={key}>{message}</p>;
    }

    return <div>{graphQLErrors}</div>;
  }

  return (
    <Collapse in={!hidden && !errorHidden && !!error}>
      <Alert
        severity="error"
        onClose={() => {
          setErrorHidden(true);
          onClose && onClose();
        }}
      >
        <div tw="text-sm">{getErrorContent()}</div>
      </Alert>
    </Collapse>
  );
};

export default ErrorApollo;
