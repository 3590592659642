import tw, { theme } from 'twin.macro';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import React from 'react';

export enum LoaderColor {
  white,
  primary,
  dark
}
type Props = {
  show?: boolean;
  color?: LoaderColor;
  position?: 'cover' | 'default';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  className?: string;
  loaderClassName?: string;
};

const loaderColorValues = {
  [LoaderColor.white]: theme`colors.white`,
  [LoaderColor.primary]: theme`colors.primary.500`,
  [LoaderColor.dark]: theme`colors.primary.700`
};

const sizes = {
  lg: 60,
  md: 50,
  sm: 30,
  xs: 20
};

export const Loader = ({
  color = LoaderColor.primary,
  show = true,
  size = 'sm',
  position = 'default',
  ...rest
}: Props) => {
  // other logic
  const colorValue = loaderColorValues[color];
  const sizeValue = sizes[size];
  return (
    <Fade
      in={show}
      tw="flex flex-col justify-center items-center"
      css={[position == 'cover' && tw`w-full h-full absolute top-0 left-0`]}
      {...rest}
    >
      <CircularProgress
        css={[{ color: colorValue }]}
        size={sizeValue}
        color="inherit"
      />
    </Fade>
  );
};
