import React, { FC } from 'react';
import 'twin.macro';
import FieldAutocomplete, {
  FieldAutocompleteProps
} from '@leuven2030/framework/Field/FieldAutocomplete';
import { useQuery } from '@apollo/client';
import GroupSwitchOptionsQuery from '@leuven2030/framework/Group/GroupSwitchOptionsQuery';
import get from 'lodash/get';
import GroupSwitchOption from '@leuven2030/framework/Group/GroupSwitchOption';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filterOptions = createFilterOptions({
  limit: 20,
  stringify: ({ title, formatted_address }) => `${title} ${formatted_address}`
});

const GroupSelect: FC<FieldAutocompleteProps> = (props) => {
  const { data, loading } = useQuery(GroupSwitchOptionsQuery);
  const options = get(data, 'group.list.managed');
  return (
    <FieldAutocomplete
      loading={loading}
      filterOptions={filterOptions}
      options={options}
      getOptionLabel={(data) => `${data.title}`}
      renderOption={(data) => <GroupSwitchOption data={data} />}
      {...props}
    />
  );
};

export default GroupSelect;
