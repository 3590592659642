import LanguageContext from '@leuven2030/framework/Language/LanguageContext';

import get from 'lodash/get';
import { useCookie } from 'react-use';
import { useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';

const LanguagePage = ({ children }) => {
  const { language } = useContext(PageContext);
  const { key = 'en', data = {} } = language || {};
  const [languageCookie, setLanguageCookie] = useCookie('language');
  function setLanguage(key: string) {
    setLanguageCookie(key);
    location.reload(false);
  }
  const context = {
    key: languageCookie || key || 'en',
    lng: (propertyPath) => get(data as any, propertyPath) || propertyPath,
    setLanguage
  };
  return (
    <LanguageContext.Provider value={context}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguagePage;
