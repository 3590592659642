import React, { useContext } from 'react';
import tw, { theme } from 'twin.macro';
import MenuIcon from '@material-ui/icons/Menu';
import UserAvatar from '@leuven2030/framework/User/UserAvatar';
import Menu, { MenuContext } from '@leuven2030/framework/Menu/Menu';

const UserMenuContainer = ({ UserMenuButton, children, ...props }) => {
  const ButtonComponent = UserMenuButton || UserMenuButtonDefault;
  return (
    <Menu triggerComponent={<ButtonComponent {...props} />}>{children}</Menu>
  );
};

const UserMenuButtonDefault = (props) => {
  const { open, handleClick } = useContext(MenuContext);
  return (
    <div
      {...props}
      tw="border rounded-sm border-transparent flex items-center justify-center cursor-pointer px-3"
      css={[open && tw`border-primary-500`]}
      onClick={handleClick}
    >
      <MenuIcon tw="text-gray-500" css={[open && tw`text-primary-500`]} />
      <UserAvatar tw="ml-3 w-10 h-10 text-sm " />
    </div>
  );
};

export default UserMenuContainer;
