import React, { FC, useContext } from 'react';
import UserContext from '@leuven2030/framework/User/UserContext';
import UserAvatar from '@leuven2030/framework/User/UserAvatar';
import 'twin.macro';
import Button from '@leuven2030/framework/Button/Button';
import UserAuthButtons from '@leuven2030/framework/User/UserAuthButtons';

const UserMenuDetails: FC = () => {
  const { user, signOut } = useContext(UserContext);

  if (!user) {
    return (
      <div tw="space-y-3" className="dark">
        <UserAuthButtons tw="py-3 px-3" />
      </div>
    );
  }

  return (
    <div tw=" py-6 px-3 space-y-3">
      <div tw="flex  items-center space-x-3  justify-between">
        <UserAvatar tw="w-12 h-12 text-xl flex-shrink-0 bg-gray-100" />
        <Button variant="outlined" size="small" onClick={signOut}>
          Sign out
        </Button>
      </div>

      <p tw="text-gray-600">{user.email}</p>
    </div>
  );
};

export default UserMenuDetails;
