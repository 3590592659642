import PoweredByGraphic from './BrandPoweredByGraphic.svg';
import React from 'react';
import 'twin.macro';
const BrandPoweredBy = () => {
  return (
    <a
      tw="block"
      href="https://www.leuven2030.be"
      target="_blank"
      rel="noreferrer"
    >
      <PoweredByGraphic tw="h-8 w-auto" />
    </a>
  );
};

export default BrandPoweredBy;
