import * as Yup from 'yup';
import forEach from 'lodash/forEach';

type Config = { rules?: any; type?: string };
const FormPrismicSchema = ({ body }) => {
  const schemaFields: { name; config: Config }[] = [];
  body.forEach(
    ({
      type,
      primary: {
        name,
        validation_min_message,
        validation_max_message,
        invalid_email_message,
        required_message
      }
    }) => {
      if (!name) {
        return;
      }
      const config: Config = {};
      if (type == 'text_field') {
        config.type = 'string';
        config.rules = {
          min: [2, validation_min_message],
          max: [50, validation_max_message],
          required: [required_message]
        };
      }
      if (type == 'email') {
        config.type = 'string';
        config.rules = {
          email: [invalid_email_message],
          required: [required_message]
        };
      }

      if (type == 'checkbox_group') {
        config.type = 'array';
        config.rules = {
          min: [1, validation_min_message]
        };
      }

      if (config.type) {
        schemaFields.push({
          name,
          config
        });
      }
    }
  );
  const yupShape = {};

  schemaFields.forEach(({ name, config: { rules, type } }) => {
    // eslint-disable-next-line import/namespace
    let yupRule = Yup[type]();
    forEach(rules, (args, key) => {
      yupRule = yupRule[key](...args);
    });
    yupShape[name] = yupRule;
  });
  return Yup.object().shape(yupShape);
};

export default FormPrismicSchema;
