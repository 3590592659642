import NavigationLink from '@leuven2030/framework/Navigation/NavigationLink';
import React from 'react';
import 'twin.macro';
const PrismicLink = ({ element, content }) => {
  const { url } = element.data;
  return (
    <NavigationLink
      tw="text-primary-500! inline-block font-bold! underline"
      url={url}
      prismicMeta={element.data}
    >
      {content}
    </NavigationLink>
  );
};

export default PrismicLink;
