import React, { useContext, useState } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import Portal from '@material-ui/core/Portal';
import 'twin.macro';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Slide from '@material-ui/core/Slide';
import ModalPopper from '@leuven2030/framework/Modal/ModalPopper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
const PagePreview = () => {
  const { preview } = useContext(PageContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [open, setOpen] = useState(preview);
  const [menuOpen, setMenuOpen] = useState(false);
  const exitPreview = () => {
    window.location.href = '/api/exit-preview';
  };
  return (
    <Portal>
      <div>
        <Slide direction="right" in={open} appear unmountOnExit>
          <div tw="fixed bottom-0 left-0 p-3 z-50 ">
            <ModalPopper
              disablePortal
              tw="z-40 relative m-3"
              placement="top"
              open={menuOpen}
              anchorEl={anchorEl}
            >
              <MenuList tw="bg-white p-3 shadow">
                <MenuItem onClick={exitPreview}>Exit Preview Mode</MenuItem>
              </MenuList>
            </ModalPopper>
            <div
              tw="bg-white p-3 shadow-lg flex items-center space-x-3 cursor-pointer"
              ref={setAnchorEl}
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
            >
              <VisibilityIcon tw="ml-3 text-gray-500" />
              <p tw="text-base">Preview Mode</p>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpen(false);
                }}
              >
                <CloseIcon tw="w-5 h-5" />
              </IconButton>
            </div>
          </div>
        </Slide>
      </div>
    </Portal>
  );
};

export default PagePreview;
