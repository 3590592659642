import 'twin.macro';
import FormStepsContainer, {
  FormStepsProps
} from '@leuven2030/framework/Form/FormStepsContainer';
import Form, {
  FormProps,
  FormRecaptchaOptions
} from '@leuven2030/framework/Form/Form';
import React, { createContext, FC } from 'react';

export const FormPrismicContext = createContext({
  data: null,
  steps: []
});

type Props = {
  data: any;
  recaptcha?: FormRecaptchaOptions;
  stepsConfig?: Partial<FormStepsProps>;
} & FormProps;
const FormPrismic: FC<Props> = ({
  children,
  recaptcha,
  stepsConfig,
  data,
  ...props
}) => {
  const { body } = data;
  const steps = getSteps();
  function getSteps() {
    const result = [];
    let currentStep = { label: null, description: null, fields: [] };
    body.forEach((field, index) => {
      const type = field.type || field.slice_type;
      if (type == 'step') {
        const {
          primary: { title, description }
        } = field;

        currentStep = {
          label: title,
          description,
          fields: []
        };
        result.push(currentStep);
        return;
      }
      if (index === 0) {
        result.push(currentStep);
      }
      currentStep.fields.push(field);
    });
    return result;
  }

  const context = {
    data,
    steps
  };

  return (
    <FormPrismicContext.Provider value={context}>
      <Form {...props} showSuccessIndicators recaptcha={recaptcha}>
        {children}
      </Form>
    </FormPrismicContext.Provider>
  );
};

export default FormPrismic;
