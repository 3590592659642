import { CheckboxWithLabel, CheckboxWithLabelProps } from 'formik-material-ui';
import React, { FC, useContext } from 'react';
import FieldContainer from '@leuven2030/framework/Field/FieldContainer';
import 'twin.macro';
import Field from '@leuven2030/framework/Field/Field';
import {
  FieldContext,
  FieldProps
} from '@leuven2030/framework/Field/FieldProvider';
import { useField, useFormikContext } from 'formik';

const FieldComponent: FC = ({ Label, ...props }) => {
  const { name } = useContext(FieldContext);
  const [field, meta] = useField(name);
  const form = useFormikContext();
  return (
    <FieldContainer {...props}>
      <div tw="border">
        <CheckboxWithLabel
          field={field}
          meta={meta}
          form={form}
          Label={Label}
          disableRipple
          disableTouchRipple
          disableFocusRipple
          type="checkbox"
        />
      </div>
    </FieldContainer>
  );
};

const FieldCheckbox: FC<FieldProps & Pick<CheckboxWithLabelProps, 'Label'>> = (
  props
) => {
  return <Field component={FieldComponent} {...props} hideInputIcon />;
};

export default FieldCheckbox;
