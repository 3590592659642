import React, { createRef, forwardRef, useContext, useEffect } from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import FieldInput from '@leuven2030/framework/Field/FieldInput';
import { AutocompleteContext } from '@leuven2030/framework/Field/FieldAutocomplete';
import 'twin.macro';
import FocusLock from 'react-focus-lock';

const FieldAutocompleteMenu = forwardRef<HTMLDivElement, any>(
  function SchoolSelectContent(
    { children, noOptions, loading, inputProps, onMouseDown, ...props },
    ref
  ) {
    const { setInputValue, PaperHeaderComponent, open } = useContext(
      AutocompleteContext
    );

    return (
      <FocusLock ref={ref} tw="w-screen max-w-lg">
        {PaperHeaderComponent && <PaperHeaderComponent />}
        <ListSubheader tw="p-3 bg-white">
          <FieldInput
            autoFocus
            placeholder="Search..."
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
          />
        </ListSubheader>
        {children}
      </FocusLock>
    );
  }
);

export default FieldAutocompleteMenu;
