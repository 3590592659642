import Link from 'next/link';
import React, { useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import NavigationMenu from '@leuven2030/food/Navigation/NavigationMenu';
import { StyleContent } from '@leuven2030/food/styles/general';
import tw from 'twin.macro';
import SearchMenu from '@leuven2030/framework/Search/SearchMenu';
import { css } from '@emotion/react';
import UserMenu from '@leuven2030/food/User/UserMenu';
import PageHeaderLogo from './PageHeaderLogo.svg';

const PageHeader = ({ variant }) => {
  const {
    prismic: { layout, search_config }
  } = useContext(PageContext);

  const { header_menu } = layout || { header_menu: [] };

  return (
    <div tw="relative z-10 mx-auto w-full max-w-screen-xl">
      <div tw="py-3  bg-primary-400 ">
        <div tw={'px-3 sm:px-14 max-w-screen-lg mx-auto w-full'}>
          <div tw="flex items-start flex-row items-center space-x-6 pb-0 h-auto justify-between relative ">
            <div tw="flex items-center space-x-3 justify-between">
              <Link href="/" passHref>
                <a tw="cursor-pointer opacity-100 hover:opacity-75  py-3 ">
                  {variant === 'full' ? (
                    <PageHeaderLogo tw="w-16 h-16" />
                  ) : (
                    <div
                      tw="text-white text-xs sm:text-sm uppercase"
                      className="font-sohnebreit"
                    >
                      voeding verbindt
                    </div>
                  )}
                </a>
              </Link>
            </div>

            <div tw="flex items-center space-x-3">
              {search_config && (
                <SearchMenu
                  triggerProps={{
                    searchInputProps: {
                      css: [
                        tw`bg-primary-300 rounded-md text-gray-600 border-primary-400 ring-transparent! placeholder-gray-200`,
                        css`
                          font-family: SohneBreit;
                          padding: 0.5rem;
                          :focus {
                            outline: none;
                            border-color: inherit;
                            background-color: white;
                          }
                        `
                      ]
                    },
                    iconButtonProps: {
                      css: tw`bg-white text-primary-500 border-white ring-transparent! placeholder-gray-200 hover:(bg-gray-100)`
                    },
                    iconProps: {
                      css: tw`text-gray-200`
                    }
                  }}
                />
              )}
              {header_menu.map(({ item }) => {
                if (!item) {
                  return;
                }
                return (
                  <NavigationMenu
                    key={item.title}
                    data={item}
                    buttonProps={{ css: tw`text-white` }}
                  />
                );
              })}
              <UserMenu />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
