import React, { createContext, FC, useEffect, useState } from 'react';

const initialContext = {
  timeout: 400,
  show: false,
  open: false,
  closing: false,
  onOpen: () => null,
  onClose: () => null
};

export const MenuMobileContext = createContext(initialContext);

const MenuMobileProvider: FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [closing, setClosing] = useState(false);
  const [show, setShow] = useState(false);
  const { timeout } = initialContext;
  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    if (open) {
      setShow(true);
    }
  }, [open]);

  function onClose() {
    if (closing) {
      return;
    }
    setShow(false);
    setTimeout(() => {
      setClosing(false);
      setOpen(false);
    }, timeout);
    setClosing(true);
  }

  function onOpen() {
    if (closing) {
      return;
    }
    setOpen(true);
  }

  const context = {
    timeout,
    show,
    closing,
    onClose,
    onOpen,
    open
  };

  return (
    <MenuMobileContext.Provider value={context}>
      {children}
    </MenuMobileContext.Provider>
  );
};

export default MenuMobileProvider;
