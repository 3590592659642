import React, { useContext, useEffect } from 'react';
import 'twin.macro';
import { SearchContext } from './SearchProvider';
import { connectRefinementList } from 'react-instantsearch-dom';
import get from 'lodash/get';
import SearchToggle from '@leuven2030/framework/Search/SearchToggle';
const RefinementList = ({ items: algoliaItems, refine, ...props }) => {
  const { selectedGroup, refineValue } = useContext(SearchContext);
  const categories = get(selectedGroup, 'categories') || [];
  if (!categories.length) {
    return <></>;
  }
  useEffect(() => {
    refine(refineValue);
  }, [refineValue]);

  useEffect(() => {
    refine(selectedGroup.refineValue || []);
  }, [selectedGroup]);

  return (
    <div tw="flex flex-col divide-y" {...props}>
      {categories.map((category) => {
        const { uid } = category;
        const item = algoliaItems.find((algoliaItem) => {
          return uid == algoliaItem.label;
        });
        return <SearchToggle key={uid} data={category} item={item} />;
      })}
    </div>
  );
};

const SearchFilters = connectRefinementList(RefinementList);

export default SearchFilters;
