import React from 'react';
import { connectPoweredBy } from 'react-instantsearch-dom';
import 'twin.macro';
const PoweredBy = ({ url }) => {
  const image =
    'https://res.cloudinary.com/hilnmyskv/image/upload/q_auto,f_auto/v1618823933/Algolia_com_Website_assets/images/shared/algolia_logo/search-by-algolia-light-background.png';
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <img src={image} tw="h-4 w-auto" />
    </a>
  );
};

const SearchAlgoliaCredit = connectPoweredBy(PoweredBy);

export default SearchAlgoliaCredit;
