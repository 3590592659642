import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import get from 'lodash/get';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import 'twin.macro';
import React, { FC, useContext } from 'react';
import { AutocompleteContext } from '@leuven2030/framework/Field/FieldAutocomplete';
import FieldAutocompleteMenu from '@leuven2030/framework/Field/FieldAutocompleteMenu';
import FieldAutocompletePopper from '@leuven2030/framework/Field/FieldAutocompletePopper';
import FieldAutocompleteTrigger from '@leuven2030/framework/Field/FieldAutocompleteTrigger';
import { Loader } from '@leuven2030/framework/Loader/Loader';

export type FieldAutocompleteContentProps = {
  item_title_path?: string;
} & Partial<AutocompleteProps<any, boolean, boolean, boolean>>;
const FieldAutocompleteContent: FC<FieldAutocompleteContentProps> = ({
  renderOption,
  options = [],
  item_title_path = 'title',
  ...rest
}) => {
  const { open, inputValue, setSelected, setOpen } = useContext(
    AutocompleteContext
  );

  function renderInput({
    InputProps: { ref },
    InputLabelProps,
    disabled,
    ...params
  }) {
    return (
      <div ref={ref}>
        <FieldAutocompleteTrigger {...params} />
      </div>
    );
  }

  function defaultRenderOption(option, { inputValue }) {
    const title = get(option, item_title_path);
    const matches = match(title, inputValue);
    const parts = parse(title, matches);
    return (
      <div>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    );
  }
  return (
    <Autocomplete
      open={open}
      inputValue={inputValue}
      openOnFocus={false}
      options={options}
      handleHomeEndKeys
      getOptionLabel={(option) => get(option, item_title_path) || ''}
      renderInput={renderInput}
      renderOption={(data, props) => {
        const render = renderOption || defaultRenderOption;
        return (
          <div
            tw="w-full"
            onClick={(e) => {
              setSelected(data);
              setOpen(false);
            }}
          >
            {render(data, props)}
          </div>
        );
      }}
      PopperComponent={FieldAutocompletePopper}
      PaperComponent={FieldAutocompleteMenu}
      noOptionsText="Geen resultaten..."
      loadingText={<Loader />}
      {...rest}
    />
  );
};

export default FieldAutocompleteContent;
