import { createContext } from 'react';

const LanguageContext = createContext<
  Partial<{
    key: string;
    lng: (propertyPath: string) => string;
    setLanguage: (key: string) => void;
  }>
>({
  key: 'en',
  lng: () => '',
  setLanguage: () => null
});

export default LanguageContext;
