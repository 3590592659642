import { useFormikContext } from 'formik';
import { gql, useQuery } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { buildYup } from 'schema-to-yup';
import { FormContext } from '@leuven2030/framework/Form/Form';

export const SchemaQuery = gql`
  query($name: String!) {
    form {
      schema(name: $name) {
        id
        json
      }
    }
  }
`;

const FormSchema = ({ name }) => {
  const { validateForm } = useFormikContext();
  const { setValidationSchema, setError, validationSchema } = useContext(
    FormContext
  );
  const { loading, data, error } = useQuery(SchemaQuery, {
    variables: {
      name
    }
  });

  useEffect(() => {
    setError(error);
    if (!data) {
      return;
    }
    const schema = buildYup(JSON.parse(data.form.schema.json), {
      errMessages: {
        first_name: {
          //TODO: Get custom error messages
          //required: 'skjdskjskdjsk'
        }
      }
    });
    setValidationSchema(schema);
  }, [data, loading, error]);

  useEffect(() => {
    if (!validationSchema) {
      return;
    }
    validateForm();
  }, [validationSchema]);

  return <></>;
};

export default FormSchema;
