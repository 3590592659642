import 'twin.macro';
import React, { createContext, FC, useMemo, useState } from 'react';
import FieldAutocompleteContent, {
  FieldAutocompleteContentProps
} from '@leuven2030/framework/Field/FieldAutocompleteContent';

export const AutocompleteContext = createContext({
  open: false,
  setOpen: (value) => null,
  inputValue: '',
  setInputValue: (value) => null,
  PaperHeaderComponent: null,
  selected: null,
  setSelected: (value) => null,
  TriggerComponent: null,
  getOptionLabel: (option) => null
});

export type FieldAutocompleteProps = {
  PaperHeaderComponent?: FC;
  onSelected?: (value) => void;
  TriggerComponent?: FC;
} & FieldAutocompleteContentProps;
const FieldAutocomplete: FC<FieldAutocompleteProps> = ({
  PaperHeaderComponent,
  TriggerComponent,
  onSelected,
  getOptionLabel,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);

  useMemo(() => {
    selected && onSelected && onSelected(selected);
  }, [selected]);
  const context = {
    inputValue,
    setInputValue,
    open,
    setOpen: (value) => {
      document.activeElement.blur();
      setTimeout(() => {
        setOpen(value);
      });
    },
    getOptionLabel,
    PaperHeaderComponent,
    selected,
    setSelected,
    TriggerComponent
  };
  return (
    <AutocompleteContext.Provider value={context}>
      <FieldAutocompleteContent {...props} />
    </AutocompleteContext.Provider>
  );
};

export default FieldAutocomplete;
