import { createContext, FC, ReactNode } from 'react';
import { InputProps } from '@material-ui/core';
import React from 'react';
export type FieldContextProps = {
  title?: string;
  description?: string;
  name?: string;
  hideInputIcon?: boolean;
  inputIcon?: ReactNode;
  hideFeedback?: boolean;
  readOnly?: boolean;
} & InputProps;
export const FieldContext = createContext<FieldContextProps>({});
export type FieldProps = FieldContextProps & {
  validate?: any;
  component?: any;
} & InputProps;

const FieldProvider: FC<FieldProps> = ({
  title,
  description,
  name,
  hideInputIcon,
  inputIcon,
  hideFeedback,
  readOnly,
  children
}) => {
  const context = {
    title,
    description,
    name,
    hideInputIcon,
    inputIcon,
    hideFeedback,
    readOnly
  };
  return (
    <FieldContext.Provider value={context}>{children}</FieldContext.Provider>
  );
};

export default FieldProvider;
