import React from 'react';
import FieldContainerBase from '@leuven2030/framework/Field/FieldContainerBase';
import { FieldAutocompleteTriggerContainer } from '@leuven2030/framework/Field/FieldAutocompleteTrigger';

const FormFieldAutocompleteTrigger = () => {
  return (
    <FieldContainerBase>
      <FieldAutocompleteTriggerContainer />
    </FieldContainerBase>
  );
};

export default FormFieldAutocompleteTrigger;
