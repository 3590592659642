import React, { useContext } from 'react';
import Stepper from '@material-ui/core/Stepper';
import { FormStepsContext } from '@leuven2030/framework/Form/FormStepsContainer';
import FormStep from '@leuven2030/framework/Form/FormStep';
import FormStepContent from '@leuven2030/framework/Form/FormStepContent';
import FormStepProvider from '@leuven2030/framework/Form/FormStepProvider';

const FormSteps = () => {
  const { activeStep, items } = useContext(FormStepsContext);

  if (items.length == 1) {
    const [item] = items;
    return (
      <FormStepProvider {...item} index={0}>
        <FormStepContent />
      </FormStepProvider>
    );
  }
  return (
    <Stepper activeStep={activeStep} orientation="vertical" tw="w-full">
      {items.map((item, index) => {
        return <FormStep key={index} index={index} {...item} />;
      })}
    </Stepper>
  );
};

export default FormSteps;
