import React, { useContext } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import tw from 'twin.macro';
import { SearchContext } from '@leuven2030/framework/Search/SearchProvider';

const SearchToggle = ({ data, item }) => {
  const { title, uid } = data;

  const { selectedGroup } = useContext(SearchContext);

  const { count, isRefined, value } = item || {
    count: 0,
    isRefined: false,
    value: null
  };
  return (
    <div
      key={uid}
      css={!value && tw`pointer-events-none opacity-50`}
      tw="flex items-center space-x-1 hover:bg-gray-200 cursor-pointer py-2 px-2 overflow-hidden whitespace-nowrap"
      onClick={(event) => {
        event.preventDefault();
        if (!value) {
          return;
        }
        selectedGroup.refine(value);
      }}
    >
      <Checkbox
        checked={isRefined}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <div tw="w-full overflow-hidden flex items-center justify-between">
        <span tw="truncate text-xs">{title}</span>{' '}
        <span tw="text-gray-400 text-xs">({count})</span>
      </div>
    </div>
  );
};

export default SearchToggle;
