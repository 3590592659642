import LanguagePage from '@leuven2030/framework/Language/LanguagePage';
import { NextPage } from 'next';
import React, { createContext } from 'react';
import UserProvider from '@leuven2030/framework/User/UserProvider';
import PagePreview from '@leuven2030/framework/Page/PagePreview';
import PrismicLinkResolver from '@leuven2030/framework/Prismic/PrismicLinkResolver';
import { PageProps } from '@leuven2030/framework/Page/PageProps';
import PageHead from '@leuven2030/framework/Page/PageHead';
const initialContext = {
  prismic: {},
  linkResolver: () => '/',
  api: {}
};
export const PageContext = createContext<PageProps>(initialContext);

const Page = (PageComponent: NextPage<any>) => {
  const PageProject = (props) => {
    const linkResolver = PrismicLinkResolver(props.resolverRules);
    return (
      <PageContext.Provider
        value={{ ...initialContext, ...props, linkResolver }}
      >
        <PageHead />
        <UserProvider>
          <LanguagePage>
            <PageComponent {...props} />
          </LanguagePage>
        </UserProvider>
        <PagePreview />
      </PageContext.Provider>
    );
  };
  return PageProject;
};

export default Page;
