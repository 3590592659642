import React, { useContext } from 'react';
import 'twin.macro';
import { FieldContext } from '@leuven2030/framework/Field/FieldProvider';

const FieldLabel = ({ ...props }) => {
  const { title, description } = useContext(FieldContext);
  if (!title && !description) {
    return <></>;
  }
  return (
    <div {...props}>
      {!!title && (
        <label tw="block text-sm font-medium leading-5 text-gray-500 text-left">
          <span>{title}</span>
        </label>
      )}
      {!!description && (
        <div tw="text-sm leading-5 text-gray-500">{description}</div>
      )}
    </div>
  );
};

export default FieldLabel;
