import React, { FC, useContext } from 'react';
import 'twin.macro';
import SchoolIcon from '@material-ui/icons/School';
import { AutocompleteContext } from '@leuven2030/framework/Field/FieldAutocomplete';
import GroupListItem from '@leuven2030/framework/Group/GroupListItem';

type Props = {
  data: any;
  Icon?: FC;
  onClick?: () => void;
};
const GroupSwitchOption: FC<Props> = ({ data, Icon, ...props }) => {
  const { id, title, formatted_address, thumbnail } = data;
  const { selected } = useContext(AutocompleteContext);
  return (
    <GroupListItem
      selected={selected ? selected.id === id : false}
      primary={title}
      secondary={formatted_address}
      Icon={Icon || (!thumbnail && SchoolIcon)}
      imageSrc={thumbnail}
      {...props}
    />
  );
};

export default GroupSwitchOption;
