import { theme } from 'twin.macro';
import { useWindowSize } from 'react-use';
import mapValues from 'lodash/mapValues';

const screensOriginal = {
  sm: theme`screens.sm`,
  md: theme`screens.md`,
  lg: theme`screens.lg`
};

const screens = mapValues(screensOriginal, (value) =>
  parseInt(value.split('px')[0])
);

const useBreakpoint = (
  { screen = 'sm' }: { screen?: 'sm' | 'md' | 'lg' } = { screen: 'sm' }
) => {
  const breakpointSize = screens[screen];
  const { width: windowWidth } = useWindowSize();
  return windowWidth < breakpointSize;
};

export default useBreakpoint;
