import React, { useContext } from 'react';
import { FormStepsContext } from '@leuven2030/framework/Form/FormStepsContainer';
import FormStepProvider from '@leuven2030/framework/Form/FormStepProvider';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { useFormikContext } from 'formik';
import tw from 'twin.macro';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getFieldState } from '@leuven2030/framework/Field/FieldContainer';
import FormStepContent from '@leuven2030/framework/Form/FormStepContent';

const FormStep = (props) => {
  const { fields, label, index } = props;
  const { activeStep, setStep } = useContext(FormStepsContext);
  const { getFieldMeta, getFieldProps } = useFormikContext();
  const hasError = fields.some((field) => {
    const name = field?.primary?.name;
    if (!name) {
      return;
    }
    const { error, touched } = getFieldState({
      field: getFieldProps(name),
      meta: getFieldMeta(name)
    });
    return !!error && touched;
  });
  const isActive = index === activeStep;

  return (
    <FormStepProvider {...props}>
      <Step key={index}>
        <StepLabel error={hasError} StepIconProps={{ completed: false }}>
          <div
            onClick={() => {
              setStep(isActive ? null : index);
            }}
            css={[
              isActive && tw`text-primary-600 font-normal`,
              !isActive && tw`text-gray-500 font-light `
            ]}
            tw="border-transparent w-full border-b  text-2xl   cursor-pointer w-full flex justify-between items-center "
          >
            <span tw="">{label}</span>
            <ExpandMoreIcon tw="transform" css={[isActive && tw`rotate-180`]} />
          </div>
        </StepLabel>
        <StepContent>
          <FormStepContent />
        </StepContent>
      </Step>
    </FormStepProvider>
  );
};

export default FormStep;
