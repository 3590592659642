import PageHeader from '@leuven2030/food/Page/PageHeader';
import React from 'react';
import tw from 'twin.macro';
import PageFooter from '@leuven2030/food/Page/PageFooter';
import { Global, css } from '@emotion/react';

const PageLayout = ({ children, variant }) => (
  <div tw="bg-gray-200">
    <Global
      styles={css`
        @font-face {
          font-family: 'SohneBreit';
          font-style: normal;
          font-weight: 500;
          src: local(''), url('/fonts/SoehneBreit-Medium.otf') format('otf'),
            url('/fonts/SoehneBreit-Medium.otf') format('truetype');
        }

        @font-face {
          font-family: 'Sohne';
          font-style: normal;
          font-weight: 400;
          src: local(''), url('/fonts/Soehne-Book.otf') format('otf'),
            url('/fonts/Soehne-Book.otf') format('truetype');
        }

        @font-face {
          font-family: 'Sohne';
          font-style: normal;
          font-weight: 500;
          src: local(''), url('/fonts/Soehne-Medium.otf') format('otf'),
            url('/fonts/Soehne-Medium.otf') format('truetype');
        }

        h1,
        h2,
        .prismic-text h1,
        .prismic-text h2,
        .font-sohnebreit,
        .MuiButtonBase-root {
          font-family: SohneBreit;
        }

        .font-sohne {
          font-family: Sohne;
        }

        h1,
        h2 {
          font-weight: 500 !important;
        }

        p {
          font-family: Sohne;
          font-weight: 300;
          font-size: 15px;
          line-height: 22px;
        }

        label > span {
          font-family: Sohne;
        }
      `}
    />

    <PageHeader
      imageHeight={500}
      imageParams={{
        blur: 60
      }}
      variant={variant}
    />
    <div tw="mx-auto min-h-screen">{children}</div>
    <PageFooter />
  </div>
);

export default PageLayout;
