import { gql } from '@apollo/client/core';

const GroupSwitchOptionsQuery = gql`
  {
    group {
      id
      list {
        managed(type: SCHOOL) {
          title
          formatted_address
          thumbnail
          cover
          id
        }
      }
    }
  }
`;

export default GroupSwitchOptionsQuery;
