import React, { FC, useContext } from 'react';
import { MenuContext } from '@leuven2030/framework/Menu/Menu';
import useBreakpoint from '@leuven2030/framework/hooks/useBreakpoint';
import { PageContext } from '@leuven2030/framework/Page/Page';
import SearchIcon from '@material-ui/icons/Search';
import SearchInput from '@leuven2030/framework/Search/SearchInput';
import IconButton from '@material-ui/core/IconButton/IconButton';

const SearchMenuTrigger: FC = ({
  iconProps,
  iconButtonProps,
  searchInputProps
}) => {
  const { handleClick } = useContext(MenuContext);
  const smBreakpoint = useBreakpoint();
  const {
    prismic: {
      search_config: { button_label }
    }
  } = useContext(PageContext);

  if (smBreakpoint) {
    return (
      <IconButton onClick={handleClick} {...iconButtonProps}>
        <SearchIcon />
      </IconButton>
    );
  }
  return (
    <SearchInput
      onClick={handleClick}
      {...searchInputProps}
      iconProps={iconProps}
    />
  );
};

export default SearchMenuTrigger;
