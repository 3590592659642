import { ChangeEvent, FC, useContext } from 'react';
import FieldContainer from '@leuven2030/framework/Field/FieldContainer';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { FieldPhoneContext } from '@leuven2030/framework/Field/FieldPhone';
import PhoneInput, {
  isValidPhoneNumber
} from 'react-phone-number-input/input-core';
import FieldPhoneInput from '@leuven2030/framework/Field/FieldPhoneInput';
import 'twin.macro';
import { FieldContext } from '@leuven2030/framework/Field/FieldProvider';
import FormFieldAutocomplete from '@leuven2030/framework/Form/FormFieldAutocomplete';
import FieldAutocomplete from '@leuven2030/framework/Field/FieldAutocomplete';

const FieldPhoneContainer: FC = () => {
  const {
    country,
    value,
    setValue,
    setCountry,
    data: { metadataJson, metadata, options }
  } = useContext(FieldPhoneContext);
  const { disabled, name, onChange } = useContext(FieldContext);

  const onCountryChange = (country) => {
    setValue('');
    setCountry(country);
  };

  const onNumberChange = (newValue) => {
    const isValid = isValidPhoneNumber(newValue || '', metadataJson);
    setValue(newValue);
    if (isValid) {
      onChange && onChange(newValue);
    }
  };

  const autocompleteProps = {
    filterOptions: filterOptions,
    options: options || [],
    getOptionLabel: ({ countryCode }) => countryCode || '',
    renderOption: function PhoneOption({ name, callingCode }) {
      return (
        <div tw="space-x-1 w-56 p-3">
          <span>{name} </span>
          <span tw="text-gray-500 text-sm">{callingCode}</span>
        </div>
      );
    },
    onSelected: onCountryChange,
    groupBy: (option) => option.continentName
  };

  return (
    <FieldContainer>
      <div tw="flex items-center space-x-3">
        <FieldAutocomplete tw="w-24" {...autocompleteProps} />
        {metadata && (
          <PhoneInput
            tw="flex-1"
            name={name}
            metadata={metadataJson}
            disabled={disabled}
            international={true}
            inputComponent={FieldPhoneInput}
            withCountryCallingCode={true}
            country={country ? country.countryCode : null}
            value={value}
            useNationalFormatForDefaultCountryValue={true}
            onChange={onNumberChange}
          />
        )}
      </div>
    </FieldContainer>
  );
};

const filterOptions = createFilterOptions({
  stringify: ({ name, countryCode, native, callingCode }) =>
    `${name} ${native} ${countryCode} ${callingCode}`
});

export default FieldPhoneContainer;
