import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import { FC, useEffect, useState } from 'react';
import startCase from 'lodash/startCase';
import 'twin.macro';
import ImageURL from '@leuven2030/framework/Image/ImageURL';
const ContactAvatar: FC<
  { email?: string; name?: string; initials?: string } & AvatarProps
> = ({ src, email, name, ...rest }) => {
  const [initials, setInitials] = useState(getInitials());
  useEffect(() => {
    setInitials(getInitials());
  }, [name, email]);

  function getInitials() {
    const initials = getInitialsFromName() || getInitialsFromEmail();
    if (!initials) {
      return;
    }
    return initials.toUpperCase();
  }
  function getInitialsFromName(value?: string) {
    value = value || name;
    if (!value) {
      return;
    }

    const parts = value.split(' ');
    if (parts.length > 1) {
      return `${parts[0][0]}${parts[1][0]}`;
    } else {
      return `${value[0]}${value[1]}`;
    }
  }
  function getInitialsFromEmail() {
    if (!email) {
      return;
    }
    return getInitialsFromName(startCase(email));
  }
  function getImageSrc() {
    if (!src) {
      return;
    }
    const imageSize = 100;
    return ImageURL(src, {
      fit: 'crop',
      crop: 'faces',
      facepad: 2,
      w: imageSize,
      h: imageSize
    });
  }
  const imageSrc = getImageSrc();

  return (
    <Avatar tw="bg-white text-gray-500" {...rest} src={imageSrc}>
      {initials && <span tw="text-gray-600">{initials}</span>}
    </Avatar>
  );
};

export default ContactAvatar;
