import React, { useContext } from 'react';
import Head from 'next/head';
import { PageContext } from '@leuven2030/framework/Page/Page';

const PageHead = () => {
  const {
    prismic: { layout }
  } = useContext(PageContext);
  if (!layout) {
    return <></>;
  }
  const { website_title } = layout;

  return <Head>{website_title && <title>{website_title}</title>}</Head>;
};

export default PageHead;
