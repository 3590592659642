import React from 'react';
import { Elements } from 'prismic-reactjs';
import PrismicLink from '@leuven2030/framework/Prismic/PrismicLink';
import PrismicImage from '@leuven2030/framework/Prismic/PrismicImage';

// -- Function to add unique key to props
const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};

// -- HTML Serializer
const PrismicHtmlSerializer = function (type, element, content, children, key) {
  const props = { type, element, content, children, key };

  const nodeProps = { children, key };

  switch (type) {
    case Elements.heading1: // Heading 1
      return <h1 {...nodeProps} />;

    case Elements.heading2: // Heading 2
      return <h2 {...nodeProps} />;

    case Elements.heading3: // Heading 3
      return <h3 {...nodeProps} />;

    case Elements.heading4: // Heading 4
      return <h4 {...nodeProps} />;

    case Elements.heading5: // Heading 5
      return <h5 {...nodeProps} />;

    case Elements.heading6: // Heading 6
      return <h6 {...nodeProps} />;

    case Elements.paragraph: // Paragraph
      return <p {...nodeProps} />;

    case Elements.preformatted: // Preformatted
      return <pre {...nodeProps} />;

    case Elements.strong: // Strong
      return <strong {...nodeProps} />;

    case Elements.em: // Emphasis
      return <em {...nodeProps} />;

    case Elements.listItem: // Unordered List Item
      return <li {...nodeProps} />;

    case Elements.oListItem: // Ordered List Item
      return <li {...nodeProps} />;

    case Elements.list: // Unordered List
      return <ul {...nodeProps} />;

    case Elements.oList: // Ordered List
      return <ol {...nodeProps} />;

    case Elements.image: // Image
      return <PrismicImage {...props} key={key} />;

    case Elements.embed: // Embed
      const embedProps = Object.assign(
        {
          'data-oembed': element.oembed.embed_url,
          'data-oembed-type': element.oembed.type,
          'data-oembed-provider': element.oembed.provider_name
        },
        element.label ? { className: element.label } : {}
      );
      const embedHtml = React.createElement('div', {
        dangerouslySetInnerHTML: { __html: element.oembed.html }
      });
      return React.createElement(
        'div',
        propsWithUniqueKey(embedProps, key),
        embedHtml
      );

    case Elements.hyperlink: // Image
      return <PrismicLink {...props} key={key} />;

    case Elements.label: // Label
      return <span {...nodeProps} />;

    case Elements.span: // Span
      if (content) {
        return content.split('\n').reduce((acc, p) => {
          if (acc.length === 0) {
            return [p];
          } else {
            const brIndex = (acc.length + 1) / 2 - 1;
            const br = React.createElement(
              'br',
              propsWithUniqueKey({}, brIndex)
            );
            return acc.concat([br, p]);
          }
        }, []);
      } else {
        return null;
      }

    default:
      // Always include a default that returns null
      return null;
  }
};

export default PrismicHtmlSerializer;
