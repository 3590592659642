import React, { FC, MouseEvent, useEffect, useState } from 'react';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper, { PopperProps } from '@material-ui/core/Popper';
import ClickAwayListener, {
  ClickAwayListenerProps
} from '@material-ui/core/ClickAwayListener';
import tw from 'twin.macro';
import MenuArrow from '@leuven2030/framework/Menu/MenuArrow';
import useBreakpoint from '@leuven2030/framework/hooks/useBreakpoint';
import MenuMobile from '@leuven2030/framework/Menu/MenuMobile';
import MenuMobileProvider from '@leuven2030/framework/Menu/MenuMobileProvider';
import { useRouter } from 'next/router';
export type MenuPopperProps = PopperProps & {
  onClose: ClickAwayListenerProps['onClickAway'];
  arrowColor?: string;
};
const MenuPopper: FC<MenuPopperProps> = ({
  children,
  onClose,
  open,
  arrowColor,
  anchorEl,
  ...props
}) => {
  const router = useRouter();
  const [initialRouterPath] = useState(router.asPath);
  useEffect(() => {
    if (initialRouterPath == router.asPath) {
      return;
    }
    onClose();
  }, [router.asPath]);

  const handleClose = (event: MouseEvent<EventTarget>) => {
    if (
      anchorEl &&
      (anchorEl as HTMLElement).contains(event.target as HTMLElement)
    ) {
      return;
    }
    onClose(event as any);
  };
  const [arrowRef, setArrowRef] = useState(null);
  const smBreakpoint = useBreakpoint();

  if (smBreakpoint) {
    return (
      <MenuMobileProvider>
        <MenuMobile open={open} onClose={onClose}>
          {children}
        </MenuMobile>
      </MenuMobileProvider>
    );
  }

  return (
    <Popper
      tw="mt-3 z-40"
      css={[!open && tw`pointer-events-none fixed`]}
      role={undefined}
      transition
      open={open}
      anchorEl={anchorEl}
      modifiers={{
        arrow: {
          enabled: true,
          element: arrowRef
        }
      }}
      {...props}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom'
          }}
        >
          <Paper elevation={16} css={[smBreakpoint && tw`rounded-none!`]}>
            <ClickAwayListener onClickAway={handleClose}>
              <div>
                <MenuArrow
                  placement={placement}
                  setArrowRef={setArrowRef}
                  color={arrowColor}
                />
                {children}
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default MenuPopper;
