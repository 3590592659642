import { css } from '@emotion/react';

const ModalArrow = css`
  position: absolute;
  font-size: 7px;
  width: 3em;
  height: 3em;
  &::before {
    content: '';
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
`;

export default ModalArrow;
