import FieldText from '@leuven2030/framework/Field/FieldText';
import FieldCheckbox from '@leuven2030/framework/Field/FieldCheckbox';
import { Alert } from '@material-ui/lab';
import PrismicText from '@leuven2030/framework/Prismic/PrismicText';
import 'twin.macro';
import React from 'react';
import FieldPhone from '@leuven2030/framework/Field/FieldPhone';
import GroupSelectFormField from '@leuven2030/framework/Group/GroupSelectFormField';
import FieldCheckboxGroup from '@leuven2030/framework/Field/FieldCheckboxGroup';
import FieldEmail from '@leuven2030/framework/Field/FieldEmail';
import FieldTextArea from '@leuven2030/framework/Field/FieldTextArea';
const FieldPrismic = ({ data, ...fieldProps }) => {
  if (!data?.primary) {
    return (
      <Alert severity="error">Check prismic query for: {data.__typename}</Alert>
    );
  }
  const { primary, fields, items } = data;
  const type = data.type || data.slice_type;
  const { label: title, ...rest } = primary;
  const props = {
    title,
    items: fields || items,
    ...rest,
    ...fieldProps
  };
  if (type == 'checkbox_group') {
    return <FieldCheckboxGroup {...props} />;
  }
  if (type == 'autocomplete') {
    return <GroupSelectFormField {...props} />;
  }
  if (type == 'text_field') {
    return <FieldText {...props} />;
  }
  if (type == 'text_area') {
    return <FieldTextArea {...props} />;
  }
  if (type == 'email') {
    return <FieldEmail {...props} />;
  }
  if (type == 'phone_number') {
    return <FieldPhone {...props} />;
  }
  if (type == 'checkbox') {
    return (
      <FieldCheckbox
        {...props}
        Label={{
          label: (
            <div tw="w-full">
              <PrismicText tw="prose prose-sm py-3 pr-3" text={primary.text} />
            </div>
          )
        }}
      />
    );
  }

  return <Alert severity="error">Field not found: {type}</Alert>;
};

export default FieldPrismic;
