import get from 'lodash/get';
import eachDeep from 'deepdash/eachDeep';
import NavigationLink from '@leuven2030/framework/Navigation/NavigationLink';
import Image from '@leuven2030/framework/Image/Image';
import React, { useContext } from 'react';
import SearchHighlight from '@leuven2030/framework/Search/SearchHighlight';
import 'twin.macro';
import { PageContext } from '@leuven2030/framework/Page/Page';

const SearchHit = ({ hit }) => {
  const {
    prismic: {
      layout: { read_more },
      allSearch_categorys: { edges: searchCategories }
    }
  } = useContext(PageContext);
  const cover = hit.image || get(hit, 'data.cover.url');
  const matches = [];
  const { node: searchCategoryFound } =
    searchCategories.find(
      ({
        node: {
          _meta: { uid }
        }
      }) => uid === hit.category
    ) || {};

  const searchCategory = searchCategoryFound || {
    title: hit.category
  };
  eachDeep(hit._highlightResult, (match, key, parentValue, context) => {
    if (!get(match, 'matchedWords.length')) {
      return;
    }
    matches.push(context.path);
  });
  const linkProps = {
    url: hit.url,
    prismicMeta: null
  };
  if (!linkProps.url) {
    linkProps.prismicMeta = hit;
  }
  return (
    <NavigationLink
      {...linkProps}
      tw=" p-3 space-y-2 flex flex-col justify-between relative max-w-full overflow-hidden hover:bg-gray-100"
    >
      <div tw="flex space-x-2 items-start">
        {cover && (
          <Image
            src={cover}
            width={80}
            height={80}
            tw="object-cover w-12 h-12 bg-gray-100 rounded-full overflow-hidden flex-shrink-0 flex-grow-0"
            imgixParams={{
              fit: 'crop',
              crop: 'faces',
              mask: 'ellipse'
            }}
          />
        )}
        <div tw="overflow-hidden">
          <SearchHighlight
            attribute="data.title"
            hit={hit}
            tw="text-gray-700 truncate w-full font-bold"
          />
          <SearchHighlight
            attribute="data.description"
            matches={matches}
            hit={hit}
            tw="text-gray-500 text-xs"
          />
        </div>
      </div>
      <div tw="flex justify-between space-x-2">
        <div tw="text-xs text-gray-500">{searchCategory.title}</div>
        <span tw="text-primary-400 text-xs">{read_more}</span>
      </div>
    </NavigationLink>
  );
};

export default SearchHit;
