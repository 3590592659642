import { forwardRef, useContext } from 'react';
import {
  isValidPhoneNumber,
  parsePhoneNumber
} from 'react-phone-number-input/input-core';
import FieldText from '@leuven2030/framework/Field/FieldText';
import 'twin.macro';
import { FieldPhoneContext } from '@leuven2030/framework/Field/FieldPhone';

type Props = {
  onChange: (value) => any;
  value: any;
};
const FieldPhoneInput = forwardRef<HTMLInputElement, Props>(
  function FieldPhoneInput({ onChange, value: inputValue, ...rest }, ref) {
    const {
      countryCode,
      value,
      data: { metadataJson, metadata }
    } = useContext(FieldPhoneContext);

    // Select a country.
    function handleOnChage(e) {
      if (!countryCode) {
        return;
      }

      if (value) {
        const newValue = e.target.value;
        const parsedValue = parsePhoneNumber(value, metadataJson);
        const parsedNewValue =
          parsedValue && parsePhoneNumber(newValue, metadataJson);

        metadata.country(countryCode);
        const maxLength = Math.max(...metadata.numberingPlan.possibleLengths());
        if (
          parsedNewValue &&
          parsedNewValue.nationalNumber.length > maxLength
        ) {
          return;
        }
      }

      onChange(e);
    }

    function validatePhonenumber() {
      const isValid = isValidPhoneNumber(inputValue, metadataJson);
      if (!isValid) {
        return 'Invalid phone number';
      }
    }

    return (
      <FieldText
        hideFeedback
        validate={validatePhonenumber}
        tw="w-full flex-1"
        value={inputValue}
        inputRef={ref}
        onChange={handleOnChage}
        {...rest}
      />
    );
  }
);
export default FieldPhoneInput;
