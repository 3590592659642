import React, { useContext } from 'react';
import { MenuContext } from '@leuven2030/framework/Menu/Menu';
import UserAvatar from '@leuven2030/framework/User/UserAvatar';
import tw from 'twin.macro';
const UserMenuButton = (props) => {
  const { open, handleClick } = useContext(MenuContext);
  return (
    <div
      {...props}
      tw="border rounded-sm border-transparent flex items-center justify-center cursor-pointer"
      css={[open && tw`border-primary-500`]}
      onClick={handleClick}
    >
      <UserAvatar tw="ml-3 w-12 h-12 text-sm " />
    </div>
  );
};

export default UserMenuButton;
