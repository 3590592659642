import React, { useContext } from 'react';
import { AutocompleteContext } from '@leuven2030/framework/Field/FieldAutocomplete';
import { InputStyle } from '@leuven2030/framework/Field/FieldInput';
import tw from 'twin.macro';

const FieldAutocompleteTrigger = ({ inputProps: { ref, ...inputProps } }) => {
  const { open, setOpen, TriggerComponent } = useContext(AutocompleteContext);
  const Component = TriggerComponent || FieldAutocompleteTriggerContainer;
  return (
    <div
      onClick={() => {
        setOpen(!open);
      }}
      tw="relative"
    >
      <input ref={ref} hidden />
      <Component {...inputProps} />
    </div>
  );
};

export const FieldAutocompleteTriggerContainer = () => {
  const { selected, getOptionLabel } = useContext(AutocompleteContext);

  const label = selected ? getOptionLabel(selected) : null;
  return (
    <div tw="cursor-pointer" css={[InputStyle, !label && tw`text-gray-500`]}>
      {label || 'Select...'}
    </div>
  );
};

export default FieldAutocompleteTrigger;
