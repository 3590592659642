import React, { useContext } from 'react';
import 'twin.macro';
import UserContext from '@leuven2030/framework/User/UserContext';
import UserMenuContainer from '@leuven2030/framework/User/UserMenuContainer';
import UserMenuDetails from '@leuven2030/food/User/UserMenuDetails';
import UserMenuList from '@leuven2030/food/User/UserMenuList';
import UserMenuButton from '@leuven2030/food/User/UserMenuButton';

const UserMenu = () => {
  const { user } = useContext(UserContext);

  if (!user) {
    return <></>;
  }
  return (
    <UserMenuContainer UserMenuButton={UserMenuButton}>
      <div tw="w-auto sm:w-64">
        <UserMenuDetails />
        <UserMenuList />
      </div>
    </UserMenuContainer>
  );
};

export default UserMenu;
