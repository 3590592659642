import { PopperProps } from '@material-ui/core/Popper';
import React, { useContext } from 'react';
import { AutocompleteContext } from '@leuven2030/framework/Field/FieldAutocomplete';
import MenuPopper from '@leuven2030/framework/Menu/MenuPopper';
import 'twin.macro';
const FieldAutocompletePopper = ({ anchorEl, open, children }: PopperProps) => {
  const { setOpen } = useContext(AutocompleteContext);
  return (
    <MenuPopper
      tw="w-auto!"
      open={open}
      placement="bottom-start"
      onClose={() => {
        setOpen(false);
      }}
      anchorEl={anchorEl}
    >
      {children}
    </MenuPopper>
  );
};

export default FieldAutocompletePopper;
