import { createContext, FC, useContext, useEffect, useState } from 'react';
import 'twin.macro';
import dynamic from 'next/dynamic';
import FieldPhoneContainer from '@leuven2030/framework/Field/FieldPhoneContainer';
import find from 'lodash/find';
import useGeoIp from 'usegeoip';
import { useField } from 'formik';
import { FieldContext, FieldProps } from '@leuven2030/framework/Field/FieldProvider';

const FieldPhoneData = dynamic(
  () => import('@leuven2030/framework/Field/FieldPhoneData')
);
const contextInitialValues = {
  value: '',
  initialValue: '',
  setValue: (value) => null,
  countryCode: '',
  data: {
    options: [],
    metadataJson: { countries: [] },
    metadata: null
  },
  setData: (value) => null,
  country: null,
  setCountry: (value) => null
};
export const FieldPhoneContext = createContext(contextInitialValues);

const FieldPhone: FC<FieldProps> = (props) => {
  const [{ value = '' }, meta, { setValue }] = useField<string>(props.name);
  const [country, setCountry] = useState(contextInitialValues.country);
  const [data, setData] = useState(contextInitialValues.data);
  const [initialValue] = useState(value);
  const { countryCode } = useGeoIp();

  useEffect(() => {
    if (!countryCode) {
      return;
    }
    if (!data.options.length) {
      return;
    }
    if (initialValue) {
      return;
    }
    const option = find(data.options, {
      countryCode
    });
    setValue('');
    setCountry(option);
  }, [countryCode, data]);

  return (
    <FieldContext.Provider value={props}>
      <FieldPhoneContext.Provider
        value={{
          initialValue,
          value,
          setValue,
          country,
          setCountry,
          data,
          setData,
          countryCode
        }}
      >
        <FieldPhoneContainer />
        <FieldPhoneData />
      </FieldPhoneContext.Provider>
    </FieldContext.Provider>
  );
};
export default FieldPhone;
