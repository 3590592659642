import React, { createContext, FC, useContext, useState } from 'react';
import FormSteps from '@leuven2030/framework/Form/FormSteps';
import 'twin.macro';
import { FormContext } from '@leuven2030/framework/Form/Form';
import StateAllDone from '@leuven2030/framework/State/StateAllDone';
import Button from '@leuven2030/framework/Button/Button';
import Link from 'next/link';
import RecaptchaMessage from '@leuven2030/framework/Recaptcha/RecaptchaMessage';
import { FormPrismicContext } from '@leuven2030/framework/Form/FormPrismic';

export type FormStepsProps = {
  size?: 'small' | 'default';
  items: {
    label: string;
    description: string;
    fields: any[];
  }[];
};

export const FormStepsContext = createContext<
  FormStepsProps & {
    activeStep: number;
    nextStep: () => void;
    previousStep: () => void;
    setStep: (value: number) => void;
  }
>({
  size: 'default',
  items: [],
  activeStep: 0,
  nextStep: () => null,
  setStep: () => null,
  previousStep: () => null
});

const FormStepsContainer: FC<FormStepsProps> = ({
  size = 'default',
  items: propItems
}) => {
  const [activeStep, setStep] = useState(0);
  const formContext = useContext(FormContext);
  const { steps: prismicItems } = useContext(FormPrismicContext);
  const { submitted, successState } = formContext;
  const items = propItems || prismicItems;
  const nextStep = () => {
    setStep((prevActiveStep) => prevActiveStep + 1);
  };

  const previousStep = () => {
    setStep((prevActiveStep) => prevActiveStep - 1);
  };

  const context = {
    size,
    items,
    activeStep,
    nextStep,
    setStep,
    previousStep
  };

  return (
    <FormStepsContext.Provider value={context}>
      {submitted && (
        <StateAllDone
          imageSize={size == 'small' ? 160 : null}
          title={successState.title}
          description={successState.description}
        >
          <Button
            variant="outlined"
            onClick={() => {
              successState.onClick(formContext);
            }}
          >
            {successState.action_label}
          </Button>
        </StateAllDone>
      )}
      {!submitted && (
        <div tw="space-y-3">
          <FormSteps />
        </div>
      )}
    </FormStepsContext.Provider>
  );
};

export default FormStepsContainer;
