import React, { FC, ReactNode, useContext } from 'react';
import 'twin.macro';
import truncate from 'lodash/truncate';
import PrismicMeta from '@leuven2030/framework/Prismic/PrismicMeta';
import SVG from 'react-inlinesvg';
import Image from '@leuven2030/framework/Image/Image';
import NavigationLink from '@leuven2030/framework/Navigation/NavigationLink';

export type NavigationMenuLinkProps = {
  href?: string;
  meta?: PrismicMeta;
  label: string;
  description: string;
  icon: ReactNode;
};

export const NavigationMenuLinkGetProps = (
  {
    label,
    description,
    page = { title: null, cover: null, url: null, _meta: null, icon: null },
    image: imageOverride,
    url
  },
  { iconOnly } = { iconOnly: false }
) => {
  const title = page.title || label;
  const cover = imageOverride || page.cover;
  const meta = page._meta || { url: url || page.url };
  let image;
  if (page.icon) {
    image = <SVG src={page.icon.url} tw="fill-current w-8 h-8" />;
  }
  if (cover && !iconOnly) {
    image = (
      <Image
        src={cover.url}
        width={50}
        height={50}
        tw="rounded-full"
        imgixParams={{
          w: 50,
          h: 50,
          crop: 'faces',
          fit: 'crop'
        }}
      />
    );
  }

  return {
    label: title,
    description: description || page.description,
    icon: image,
    href: url || page.url,
    meta
  };
};
const NavigationMenuLink: FC<NavigationMenuLinkProps> = ({
  href,
  description,
  label,
  meta,
  icon,
  ...props
}) => {
  const content = (
    <div
      tw="-m-3 p-3 flex items-start space-x-4 hover:bg-gray-50 transition ease-in-out duration-150 cursor-pointer"
      {...props}
    >
      <div tw="space-y-1">
        <p tw="text-sm leading-4 font-medium text-gray-900">{label}</p>
        {/* <p tw="text-sm leading-5 text-gray-500">
          {truncate(description, { length: 70 })}
        </p> */}
      </div>
    </div>
  );
  return (
    <NavigationLink prismicMeta={meta} url={href}>
      {content}
    </NavigationLink>
  );
};

export default NavigationMenuLink;
