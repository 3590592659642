import MaterialButton, {
  ButtonProps as MaterialButtonProps
} from '@material-ui/core/Button/Button';
import { Loader, LoaderColor } from '@leuven2030/framework/Loader/Loader';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import 'twin.macro';
import NavigationLink, {
  NavigationLinkProps
} from '@leuven2030/framework/Navigation/NavigationLink';
export type ButtonProps = MaterialButtonProps & {
  loading?: boolean;
  link?: NavigationLinkProps;
};
const Button: ForwardRefRenderFunction<any, ButtonProps> = (
  { loading, children, link, ...rest },
  ref
) => {
  const button = (
    <MaterialButton
      disableElevation={true}
      ref={ref}
      tw="whitespace-nowrap"
      {...rest}
    >
      {loading && <Loader size="xs" color={LoaderColor.white} />}
      {!loading && children}
    </MaterialButton>
  );

  if (link) {
    return <NavigationLink {...link}>{button}</NavigationLink>;
  }

  return button;
};

export default forwardRef(Button);
