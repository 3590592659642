import { FC, useContext } from 'react';
import UserContext from '@leuven2030/framework/User/UserContext';
import 'twin.macro';
import ContactAvatar from '@leuven2030/framework/Contact/ContactAvatar';
const UserAvatar: FC = ({ ...rest }) => {
  const { user } = useContext(UserContext);

  return <ContactAvatar tw="bg-white text-gray-500" {...user} {...rest} />;
};

export default UserAvatar;
