import 'twin.macro';
import React, { useContext, useEffect, useState } from 'react';
import { WidgetPrismicContext } from '@leuven2030/framework/Widget/WidgetPrismic';
import { WidgetContext } from '@leuven2030/framework/Widget/WidgetInstance';
import FormPrismic from '@leuven2030/framework/Form/FormPrismic';
import FormPrismicSchema from '@leuven2030/framework/Form/FormPrismicSchema';
import { useMutation, gql } from '@apollo/client';
import PrismicText from '@leuven2030/framework/Prismic/PrismicText';
import { PageContext } from '@leuven2030/framework/Page/Page';
import RecaptchaMessage from '@leuven2030/framework/Recaptcha/RecaptchaMessage';
import FormStepsContainer from '@leuven2030/framework/Form/FormStepsContainer';

const testValues = {
  email: 'test@test.be',
  name: 'Test',
  list: ['LEUVEN2030']
};
const initialValuesDefault = {
  email: '',
  name: '',
  list: []
};

const mutation = gql`
  mutation($input: SubscriptionMutationAddInput!) {
    subscription {
      add(input: $input)
    }
  }
`;

export default function SubscriptionForm() {
  const [submit] = useMutation(mutation);
  const { data: widgetData } = useContext(WidgetPrismicContext);

  const { prismic } = useContext(PageContext);

  const { setShowContent, setPreferences, preferences } = useContext(
    WidgetContext
  );
  const [schema, setSchema] = useState(null);

  const form = prismic.subscribe_form || widgetData.form;
  const form_config = prismic.form_config || widgetData.form_config;
  const { title } = prismic.subscription_modal || {};

  function getInitialValues() {
    if (!form.initial_values) {
      return initialValuesDefault;
    }
    return { ...initialValuesDefault, ...JSON.parse(form.initial_values) };
  }

  const { recaptcha_message } = form_config;
  const { success_state, body } = form;
  const initialValues = getInitialValues();
  async function onSubmit(values, { setSubmitting }) {
    //setShow(false);
    await submit({
      variables: {
        input: values
      }
    });
    setPreferences({
      ...preferences,
      reacted: true
    });
    setSubmitting(false);
  }

  useEffect(() => {
    if (!form) {
      return;
    }

    setSchema(
      FormPrismicSchema({
        body
      })
    );
  }, [form]);

  return (
    <div tw="w-screen max-w-sm">
      <p tw="pt-6 px-6 text-xl text-black font-medium">{title}</p>
      <FormPrismic
        successState={{
          title: success_state.title,
          description: <PrismicText text={success_state.text} />,
          onClick: (form) => {
            setShowContent(false);
            setTimeout(() => {
              form.setSubmitted(false);
            }, 400);
          },
          action_label: success_state.action_label
        }}
        recaptcha={{
          action: 'subscribe',
          text: recaptcha_message
        }}
        validationSchema={schema}
        data={form}
        tw="space-y-6 w-full"
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        <div tw="px-6 py-6">
          <FormStepsContainer size="small" />
        </div>
        <div tw="p-3 px-6 ">
          <div tw="w-full border-t border-gray-100 py-3"></div>
          <RecaptchaMessage />
        </div>
      </FormPrismic>
    </div>
  );
}
