import Fab from '@material-ui/core/Fab';
import 'twin.macro';
import React from 'react';
import useBreakpoint from '@leuven2030/framework/hooks/useBreakpoint';
const ButtonBottom = ({ onClick, icon, ...rest }) => {
  const smBreakpoint = useBreakpoint({
    screen: 'lg'
  });
  if (!smBreakpoint) {
    return <></>;
  }
  return (
    <div
      tw="fixed bottom-0 left-0 w-screen h-20 z-10 flex items-center justify-center m-0!"
      {...rest}
    >
      <Fab tw="bg-white" color="inherit" aria-label="add" onClick={onClick}>
        {icon}
      </Fab>
    </div>
  );
};

export default ButtonBottom;
