import Button from '@leuven2030/framework/Button/Button';
import CloseIcon from '@material-ui/icons/Close';
import ButtonBottom from '@leuven2030/framework/Button/ButtonBottom';
import React from 'react';
import useBodyLock from '@leuven2030/framework/hooks/useBodyLock';
import MaterialDialogContent from '@material-ui/core/DialogContent';
import 'twin.macro';

const ModalDialogContent = ({
  onClose,
  title,
  titleProps,
  children,
  ...rest
}) => {
  useBodyLock();
  return (
    <div {...rest}>
      <div tw="items-start w-full flex justify-between">
        <div {...titleProps}>{title}</div>
        <Button tw="bg-gray-100 hidden sm:block" onClick={onClose}>
          <CloseIcon />
        </Button>
      </div>
      <MaterialDialogContent tw=" p-0! m-0! h-screen sm:(m-12 h-auto) overflow-x-hidden">
        {children}
      </MaterialDialogContent>
      <ButtonBottom onClick={onClose} icon={<CloseIcon />} />
    </div>
  );
};

export default ModalDialogContent;
