import React, { FC } from 'react';
import { useImageUrl } from '@leuven2030/framework/Image/ImageURL';

const ImageBackground: FC<{
  src: string;
  className?: string;
  width?: number;
  height?: number;
  imgixParams?: any;
  alt?: string;
}> = ({ src, className, imgixParams, alt, ...props }) => {
  const { width, height } = props;

  const { fallbackUrl, imageUrl } = useImageUrl({
    src,
    imgixParams,
    defaultParams: {
      w: width,
      h: height
    }
  });

  return (
    <div
      className={`lazyload ${className}`}
      css={{
        backgroundImage: `url('${fallbackUrl}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
      data-bg={imageUrl}
      {...props}
    />
  );
};

export default ImageBackground;
