import React, {
  createContext,
  FC,
  MouseEvent,
  ReactNode,
  useState
} from 'react';
import MenuPopper, {
  MenuPopperProps
} from '@leuven2030/framework/Menu/MenuPopper';

type MenuMobileConfig = {
  fillHeight?: boolean;
};
const mobileConfigInitial = { fillHeight: false };
export const MenuContext = createContext<{
  handleClick: (event) => void;
  handleClose: (event) => void;
  mobileConfig?: MenuMobileConfig;
  open: boolean;
}>({
  handleClick: (event: MouseEvent<HTMLElement>) => null,
  handleClose: () => null,
  open: false,
  mobileConfig: mobileConfigInitial
});
type Props = {
  triggerComponent: ReactNode;
  MenuPopperProps?: MenuPopperProps;
} & {
  mobileConfig?: MenuMobileConfig;
};
const Menu: FC<Props> = ({
  mobileConfig = mobileConfigInitial,
  triggerComponent,
  MenuPopperProps,
  children
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  }
  const open = Boolean(anchorEl);

  return (
    <MenuContext.Provider
      value={{ mobileConfig, handleClick, handleClose, open }}
    >
      {triggerComponent}
      <MenuPopper
        popperOptions={{
          placement: 'bottom',
          modifiers: {
            flip: {
              enabled: false
            },
            preventOverflow: {
              escapeWithReference: false
            }
          }
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...MenuPopperProps}
      >
        {children}
      </MenuPopper>
    </MenuContext.Provider>
  );
};

export default Menu;
