import NavigationLink from '@leuven2030/framework/Navigation/NavigationLink';
import React, { useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import 'twin.macro';
const UserMenuList = () => {
  const {
    prismic: {
      user_menu: { items }
    }
  } = useContext(PageContext);

  return (
    <div tw="bg-gray-100">
      {items.map(({ link: { _meta, title } }) => {
        return (
          <NavigationLink
            prismicMeta={_meta}
            key={_meta.uid}
            tw="p-3 hover:(bg-gray-200)"
          >
            {title}
          </NavigationLink>
        );
      })}
    </div>
  );
};

export default UserMenuList;
