import 'twin.macro';
import SubscribeButton from '@leuven2030/food/Subscription/SubscribeButton';
import LayoutFooterMenu from '@leuven2030/food/Layout/LayoutFooterMenu';
import React, { useContext } from 'react';
import { StyleContent } from '@leuven2030/framework/styles/general';
import { PageContext } from '@leuven2030/framework/Page/Page';
import BrandPoweredBy from '@leuven2030/framework/Brand/BrandPoweredBy';
const PageFooter = () => {
  const {
    prismic: { layout }
  } = useContext(PageContext);

  if (!layout) {
    return <></>;
  }
  const { copyright_notice } = layout;
  return (
    <footer tw="bg-gray-700 ">
      <div css={StyleContent}>
        <div tw="container mx-auto px-0">
          <div tw="lg:(grid grid-cols-3 gap-8) py-12" className="font-sohne">
            <LayoutFooterMenu />
            <div
              tw="mt-8 lg:mt-0 flex sm:justify-end items-start"
              className="font-sohnebreit"
            >
              <SubscribeButton />
            </div>
          </div>
          <div tw=" py-3  md:(flex items-center justify-between)">
            <p tw="text-base leading-6 text-gray-300 opacity-50">
              &copy; {new Date().getFullYear()} {copyright_notice}
            </p>
            <BrandPoweredBy />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default PageFooter;
