import Button, { ButtonProps } from '@leuven2030/framework/Button/Button';
import React, { FC, useContext } from 'react';
import { useFormikContext } from 'formik';
import 'twin.macro';
import { FormContext } from '@leuven2030/framework/Form/Form';
import { FormPrismicContext } from '@leuven2030/framework/Form/FormPrismic';
import RecaptchaButton from '@leuven2030/framework/Recaptcha/RecaptchaButton';
import get from 'lodash/get';
const FormSubmit: FC<ButtonProps> = ({ children, ...rest }) => {
  const { isValid, submitForm, isSubmitting } = useFormikContext();
  const { setError, setSubmitted, recaptcha } = useContext(FormContext);
  const { data: prismicData } = useContext(FormPrismicContext);

  function getLabel() {
    const prismicLabel = get(prismicData, 'submit_button_label');
    if (children) {
      return children;
    }
    if (prismicLabel) {
      return prismicLabel;
    }
    return 'Verzenden';
  }

  async function onClick(e) {
    e && e.preventDefault();
    await submitForm()
      .then(() => {
        setSubmitted(true);
      })
      .catch((error) => {
        setError(error);
      });
  }
  const buttonProps: ButtonProps = {
    loading: isSubmitting,
    disabled: isSubmitting || !isValid,
    type: 'submit',
    variant: 'contained',
    color: 'primary',
    children: getLabel(),
    onClick
  };
  if (recaptcha) {
    return (
      <RecaptchaButton recaptchaAction={recaptcha.action} {...buttonProps} />
    );
  }
  return <Button {...buttonProps} {...rest} />;
};

export default FormSubmit;
