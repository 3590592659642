import { FC } from 'react';
import { Field as FormikField } from 'formik';
import FieldProvider, { FieldProps } from '@leuven2030/framework/Field/FieldProvider';

const Field: FC<FieldProps> = (props) => {
  const {
    name,
    title,
    description,
    hideFeedback,
    error,
    hideInputIcon,
    inputIcon,
    ...rest
  } = props;
  return (
    <FieldProvider
      {...{
        name,
        hideFeedback,
        hideInputIcon,
        error,
        inputIcon,
        title,
        description
      }}
    >
      <FormikField {...{ name }} {...rest} />
    </FieldProvider>
  );
};

export default Field;
