import { FC, useContext } from 'react';
import UserContext from '@leuven2030/framework/User/UserContext';
import 'twin.macro';
import Button from '@leuven2030/framework/Button/Button';
type Props = {
  user?: any;
};
const UserAuthButtons: FC<Props> = (props) => {
  const { signIn, signUp } = useContext(UserContext);

  return (
    <div tw="space-y-3 flex flex-col " {...props}>
      <Button variant="outlined" onClick={signIn}>
        Sign in
      </Button>
      <span tw="text-center text-gray-500 text-sm">
        if you do not have an account:
      </span>
      <Button variant="outlined" onClick={signUp}>
        Sign up
      </Button>
    </div>
  );
};

export default UserAuthButtons;
