import React, { FC } from 'react';
import { useImageUrl } from '@leuven2030/framework/Image/ImageURL';
import pick from 'lodash/pick';
const Image: FC<{
  src: string;
  className?: string;
  width?: number;
  height?: number;
  imgixParams?: any;
  alt?: string;
}> = ({ src, className, imgixParams, alt, ...props }) => {
  const { width, height } = props;
  const { fallbackUrl, imageUrl } = useImageUrl({
    src,
    defaultParams: {
      w: width,
      h: height
    },
    imgixParams
  });
  const imgProps = pick(props, ['css', 'onClick']);

  return (
    <img
      width={width || imgixParams.w}
      height={height || imgixParams.h}
      alt={alt}
      className={`lazyload ${className}`}
      src={fallbackUrl}
      data-src={imageUrl}
      {...imgProps}
    />
  );
};

export default Image;
