import Button from '@leuven2030/framework/Button/Button';
import tw from 'twin.macro';
import ModalDialog from '@leuven2030/framework/Modal/ModalDialog';
import React, { FC, useContext, useState } from 'react';
import { useWindowSize } from 'react-use';
import { PageContext } from '@leuven2030/framework/Page/Page';
import { ButtonProps } from '@material-ui/core/Button/Button';
import SubscriptionForm from '@leuven2030/food/Subscription/SubscriptionForm';
import processPrismicData from '@leuven2030/framework/Prismic/processPrismicData';

type Props = {
  color?: ButtonProps['color'];
  orientation?: 'horizontal' | 'vertical';
};
const NewsSubscribe: FC<Props> = ({
  orientation = 'horizontal',
  buttonColor = 'primary',
  ...props
}) => {
  //const { loading, error, data } = useQuery(query);
  const {
    prismic: {
      news_settings: { subscribe_button_label },
      subscription_modal
    }
  } = useContext(PageContext);

  const { title } = subscription_modal || {};

  const [dialogOpen, setDialogOpen] = useState(false);
  const { height } = useWindowSize();
  const iframeHeight = Math.min(height - 150, 400);
  const onSubmit = () => {
    setDialogOpen(true);
  };
  return (
    <>
      <div
        css={[
          tw`flex flex-col space-y-3 items-stretch`,
          orientation == 'horizontal' && tw`sm:(flex-row space-x-3 space-y-0)`
        ]}
        {...props}
      >
        <Button
          variant="contained"
          onClick={() => {
            setDialogOpen(true);
          }}
          css={
            buttonColor === 'white'
              ? [
                  tw`bg-white text-primary-400 hover:bg-primary-300 hover:text-white`
                ]
              : [
                  tw`bg-primary-400 text-white hover:bg-primary-100 hover:text-primary-400`
                ]
          }
        >
          {subscribe_button_label}
        </Button>
      </div>
      <ModalDialog
        tw="w-screen max-w-sm"
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <SubscriptionForm />
      </ModalDialog>
    </>
  );
};

export default NewsSubscribe;
