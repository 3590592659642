import React, { FC, ReactNode, useContext, useEffect } from 'react';
import FieldContainer from '@leuven2030/framework/Field/FieldContainer';
import 'twin.macro';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import remove from 'lodash/remove';
import uniq from 'lodash/uniq';
import includes from 'lodash/includes';
import {
  FieldContext,
  FieldProps
} from '@leuven2030/framework/Field/FieldProvider';
import { useField, useFormikContext } from 'formik';
import Field from '@leuven2030/framework/Field/Field';

type Props = {
  items: {
    value: string;
    label: ReactNode | string;
  }[];
};
const FieldComponent: FC<Props> = ({ items }) => {
  const { name } = useContext(FieldContext);
  const [field] = useField(name);
  const form = useFormikContext();
  const fieldValue = field.value || [];
  return (
    <FieldContainer>
      <div tw="flex flex-col space-y-3">
        {items.map(({ value, label }, index) => {
          return (
            <FormControlLabel
              label={label}
              control={
                <Checkbox
                  value={includes(fieldValue, value)}
                  checked={includes(fieldValue, value)}
                  onChange={(event, checked) => {
                    let newFieldValue = [].concat(fieldValue);
                    if (checked) {
                      newFieldValue.push(value);
                    } else {
                      newFieldValue = newFieldValue.filter(
                        (filteredValue) => filteredValue != value
                      );
                    }
                    newFieldValue = uniq(newFieldValue);
                    form.setFieldValue(name, newFieldValue);
                  }}
                  disableRipple
                  disableTouchRipple
                  disableFocusRipple
                />
              }
              key={index}
            />
          );
        })}
      </div>
    </FieldContainer>
  );
};

const FieldCheckboxGroup: FC<FieldProps & Props> = (props) => {
  return <Field component={FieldComponent} {...props} hideInputIcon />;
};

export default FieldCheckboxGroup;
