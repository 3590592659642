import dynamic from 'next/dynamic';
import UserContext, {
  UserContextProps
} from '@leuven2030/framework/User/UserContext';
import React, { useState } from 'react';
import AuthConfig from '@leuven2030/framework/Auth/AuthConfig';

const UserDynamic = dynamic(
  () => import('@leuven2030/framework/User/UserProviderDynamic')
);

export type UserProviderProps = {
  override?: any;
};

const UserProvider = ({ children, ...props }) => {
  const [userContext, setUserContext] = useState<UserContextProps>({
    isLoggedin: false,
    signOut: loadingMessage,
    signIn: loadingMessage,
    signUp: loadingMessage,
    reloadUserInfo: loadingMessage
  });
  async function loadingMessage() {
    console.warn('Auth Component is still loading');
  }
  return (
    <UserContext.Provider value={userContext}>
      {children}
      {AuthConfig.isValid() && (
        <UserDynamic setUserContext={setUserContext} {...props} />
      )}
    </UserContext.Provider>
  );
};

export default UserProvider;
