import FieldLabel from '@leuven2030/framework/Field/FieldLabel';
import React, { FC, useContext } from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import 'twin.macro';
import FieldInputWithIcons from '@leuven2030/framework/Field/FieldInputWithIcons';
import { FieldContext } from '@leuven2030/framework/Field/FieldProvider';
import { FormContext } from '@leuven2030/framework/Form/Form';
import Collapse from '@material-ui/core/Collapse';
import { FieldState } from '@leuven2030/framework/Field/FieldContainer';

const FieldContainerBase: FC<Partial<FieldState>> = ({
  error,
  touched,
  isValid,
  children,
  ...rest
}) => {
  const { hideFeedback, hideInputIcon, inputIcon, name } = useContext(
    FieldContext
  );
  const { showSuccessIndicators } = useContext(FormContext);

  function getIcon() {
    if (hideInputIcon) {
      return;
    }

    if (!hideFeedback) {
      if (touched && error) return <ErrorIcon tw="h-5 w-5 text-red-500" />;
      if (isValid && showSuccessIndicators)
        return <CheckCircleIcon tw=" h-5 w-5 text-primary-400" />;
    }
  }
  const feedbackIcon = getIcon();

  return (
    <div tw="flex flex-col" {...rest}>
      <FieldLabel tw="text-gray-500 mb-1" />
      <FieldInputWithIcons icons={[inputIcon, feedbackIcon]}>
        {children}
      </FieldInputWithIcons>
      <Collapse in={!hideFeedback && !!error}>
        <p tw="text-sm text-red-600 h-4 mt-2">{error}</p>
      </Collapse>
    </div>
  );
};
export default FieldContainerBase;
