import React, { createContext, FC } from 'react';
import { NavigationLinkModals } from '@leuven2030/framework/Navigation/NavigationLink';

type Props = {
  modals?: NavigationLinkModals;
};

export const NavigationContext = createContext<Props>({
  modals: []
});

const NavigationProvider: FC<Props> = ({ children, modals = [] }) => {
  return (
    <NavigationContext.Provider
      value={{
        modals
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
