import { connectHighlight } from 'react-instantsearch-dom';
import React from 'react';
import truncate from 'lodash/truncate';
import 'twin.macro';

const HighlightMark = (props) => (
  <span tw="font-bold bg-primary-500 text-white rounded-sm" {...props} />
);
const HighlightText = (props) => <span tw="break-all" {...props} />;
const Highlight = ({ highlight, attribute, hit, matches = [], ...props }) => {
  const snippetResult = highlight({
    highlightProperty: '_snippetResult',
    attribute,
    hit
  });
  const highlightResult = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit
  });

  let parsedHit = snippetResult.length ? snippetResult : highlightResult;
  if (!parsedHit.length && matches.length) {
    const [firstMatch] = matches;

    parsedHit = highlight({
      highlightProperty: '_highlightResult',
      attribute: firstMatch,
      hit
    });
  }

  const [first, second, third] = parsedHit;

  function getContent() {
    if (!first) {
      return <></>;
    }
    if (first.isHighlighted) {
      return (
        <>
          <HighlightMark>{first.value}</HighlightMark>
          {second && (
            <HighlightText>
              {truncate(second.value, { length: 100 })}
            </HighlightText>
          )}
        </>
      );
    }

    return (
      <>
        <HighlightText>{truncate(first.value, { length: 100 })}</HighlightText>
        {second && <HighlightMark>{second.value}</HighlightMark>}
        {third && (
          <HighlightText>
            {truncate(third.value, { length: 100 })}
          </HighlightText>
        )}
      </>
    );
  }

  return (
    <div {...props} tw="break-words">
      {getContent()}
    </div>
  );
};

const SearchHighlight = connectHighlight(Highlight);

export default SearchHighlight;
