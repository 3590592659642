import { css } from '@emotion/react';

const ModalStyle = css`
  z-index: 1;
  &[x-placement*='bottom'] .arrow {
    top: 0;
    left: 0;
    margin-top: -0.9em;
    width: 3em;
    height: 1em;
    &::before {
      border-width: 0 1em 1em 1em;
      border-color: transparent transparent white transparent;
    }
  }
  &[x-placement*='top'] .arrow {
    bottom: 0;
    left: 0;
    margin-bottom: -0.9em;
    width: 3em;
    height: 1em;
    &::before {
      border-width: 1em 1em 0 1em;
      border-color: white transparent transparent transparent;
    }
  }
  &[x-placement='right'] .arrow {
    left: 0;
    margin-left: -0.9em;
    height: 3em;
    width: 1em;
    &::before {
      border-width: 1em 1em 1em 0;
      border-color: transparent white transparent transparent;
    }
  }
  &[x-placement*='left'] .arrow {
    right: 0;
    margin-right: -0.9em;
    height: 3em;
    width: 1em;
    &::before {
      border-width: 1em 0 1em 1em;
      border-color: transparent transparent transparent white;
    }
  }
`;

export default ModalStyle;
