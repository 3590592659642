import React, { FC } from 'react';
import 'twin.macro';
import NavigationMenuLink, {
  NavigationMenuLinkGetProps
} from '@leuven2030/food/Navigation/NavigationMenuLink';
const NavigationMenuItem: FC<any> = ({ data, first, ...props }) => {
  const navItemProps = NavigationMenuLinkGetProps(data);
  const {
    page: { items }
  } = data;
  if (items) {
    return (
      <div
        tw="mb-4 border-gray-100 border-t-2 px-6 py-2"
        style={{
          borderTopWidth: first ? '0px' : '2px'
        }}
      >
        <h3
          tw="mb-3 mt-3 text-base text-primary-400 uppercase"
          className="font-sohnebreit"
        >
          {navItemProps.label}
        </h3>
        <div tw="">
          {items.map((item, index) => (
            <NavigationMenuItem key={index} data={item} />
          ))}
        </div>
      </div>
    );
  }
  return (
    <NavigationMenuLink tw="w-full max-w-xs" {...props} {...navItemProps} />
  );
};

export default NavigationMenuItem;
