import { useLayoutEffect } from 'react';

const useBodyLock = () => {
  useLayoutEffect(
    () => {
      document.body.style.overflowY = document.documentElement.style.overflowY =
        'hidden';
      //window.scrollTo(0, 0);
      return () =>
        (document.body.style.overflowY = document.documentElement.style.overflowY = null);
    },
    [] //empty array to ensures effect is only run when mount and unmount
  );
};

export default useBodyLock;
