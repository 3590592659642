import React, { forwardRef, ReactNode } from 'react';
import 'twin.macro';
import { css } from '@emotion/react';
import FieldInput from '@leuven2030/framework/Field/FieldInput';
export type FieldContentProps = {
  children: ReactNode;
  icons?: ReactNode[];
  className?: string;
};
const FieldInputWithIcons = forwardRef<HTMLDivElement, FieldContentProps>(
  function InputWithIcons({ children, icons = [], ...props }, ref) {
    const iconsFiltered = icons.filter((icon) => !!icon);
    return (
      <div
        tw="relative"
        ref={ref}
        css={[
          iconsFiltered.length &&
            css`
              .field,
              input,
              textarea {
                padding-right: ${24 * iconsFiltered.length + 16}px;
              }
            `
        ]}
      >
        {children || <FieldInput {...props} />}
        {!!iconsFiltered.length && (
          <div tw="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none h-full">
            <div tw="flex items-center space-x-1">
              {iconsFiltered.map((icon, index) => (
                <React.Fragment key={index}>{icon}</React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default FieldInputWithIcons;
