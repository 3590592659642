import Link from 'next/link';
import PrismicMeta from '@leuven2030/framework/Prismic/PrismicMeta';
import React, { FC, useContext } from 'react';
import tw from 'twin.macro';
import NavigationLinkModal from '@leuven2030/framework/Navigation/NavigationLinkModal';
import { PageContext } from '@leuven2030/framework/Page/Page';

export type NavigationLinkModals = {
  uid: string;
  Component: FC;
}[];

export type NavigationLinkProps = {
  prismicMeta?: PrismicMeta;
  url?: string;
};
const NavigationLink: FC<NavigationLinkProps> = ({
  children,
  prismicMeta,
  url,
  ...rest
}) => {
  const { linkResolver } = useContext(PageContext);
  const style = tw`cursor-pointer block`;
  const props = {
    css: style,
    ...rest
  };

  if (url) {
    return (
      <a href={url} {...props} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }
  if (prismicMeta) {
    if (prismicMeta.type == 'modal') {
      return (
        <NavigationLinkModal prismicMeta={prismicMeta} {...props}>
          {children}
        </NavigationLinkModal>
      );
    }

    return (
      <Link href={linkResolver(prismicMeta)} passHref>
        <a {...props}>{children}</a>
      </Link>
    );
  }
  return <a {...props}>{children}</a>;
};

export default NavigationLink;
