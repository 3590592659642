import React, { FC, HTMLAttributes, useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import { connectSearchBox } from 'react-instantsearch-dom';
import { SearchBoxProvided } from 'react-instantsearch-core';
import 'twin.macro';
import FieldInputWithIcons from '@leuven2030/framework/Field/FieldInputWithIcons';
import SearchIcon from '@material-ui/icons/Search';

const Box: FC<SearchBoxProvided> = (props) => {
  const {
    currentRefinement,
    refine,
    isSearchStalled,
    indexContextValue,
    createURL,
    iconProps,
    ...rest
  } = props;
  const {
    prismic: {
      search_config: { input_placeholder }
    }
  } = useContext(PageContext);
  return (
    <FieldInputWithIcons
      icons={[<SearchIcon tw="text-gray-400" {...iconProps} />]}
      placeholder={input_placeholder}
      type="search"
      value={currentRefinement}
      onChange={(event) => refine(event.currentTarget.value)}
      {...rest}
    />
  );
};

const SearchBox = connectSearchBox(Box);

const SearchInput: FC<HTMLAttributes<HTMLInputElement>> = (props) => (
  <SearchBox {...props} />
);

export default SearchInput;
