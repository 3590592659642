import { RichText } from 'prismic-reactjs';
import 'twin.macro';
import React, { FC } from 'react';
import PrismicHtmlSerializer from '@leuven2030/framework/Prismic/PrismicHtmlSerializer';

const PrismicText: FC<{ className?: string; text: any }> = ({
  text,
  className,
  ...rest
}) => {
  return (
    <div {...rest} className={`prismic-text ${className}`}>
      <RichText render={text} htmlSerializer={PrismicHtmlSerializer} />
    </div>
  );
};

export default PrismicText;
