import React, { createContext, FC, useMemo, useState } from 'react';
import WidgetContainer from '@leuven2030/framework/Widget/WidgetContainer';
import WidgetController from '@leuven2030/framework/Widget/WidgetController';
import { useLocalStorage } from 'react-use';
import get from 'lodash/get';
import set from 'lodash/set';
export type WidgetRule = {
  page_path: string;
  timeout: number;
  scroll_position: string;
};
export type WidgetPreferences = {
  reacted: boolean;
  closed?: boolean;
};
type WidgetContextProps = {
  showFab?: boolean;
  setShowFab?: (value: boolean) => void;
  show?: boolean;
  setShow?: (value: boolean) => void;
  close?: () => void;
  showContent?: boolean;
  setShowContent?: (value: boolean) => void;
  cover?: string;
  Icon?: FC;
  preferences?: WidgetPreferences;
  setPreferences?: (value: WidgetPreferences) => void;
};
export const WidgetContext = createContext<WidgetContextProps>({
  setPreferences: () => null,
  setShowContent: () => null
});

const WidgetInstance: FC<{
  Icon?: FC;
  cover?: string;
  Content: FC;
  id: string;
}> = ({ Icon, cover, Content, id }) => {
  const [widgetPreferences, setWidgetPreferences] = useLocalStorage(
    'app-widget',
    {}
  );
  const [show, setShow] = useState(false);
  const [showFab, setShowFab] = useState(true);
  const [showContent, setShowContent] = useState(false);
  const [ignoreChange, setIgnoreChange] = useState(true);

  function setPreferences(value: WidgetPreferences) {
    const preferences = { ...widgetPreferences };
    set(preferences, id, value);
    setWidgetPreferences(preferences);
  }

  function close() {
    setPreferences({ reacted: true, closed: true });
    setShow(false);
  }

  const preferences = get(widgetPreferences, id) || { reacted: false };

  const context: WidgetContextProps = {
    showFab,
    setShowFab,
    show,
    setShow,
    showContent,
    setShowContent,
    Icon,
    cover,
    close,
    preferences,
    setPreferences
  };

  useMemo(() => {
    if (ignoreChange) {
      setIgnoreChange(false);
      return;
    }
    if (!show) {
      //setShowContent(false);
      setTimeout(() => {
        setShowFab(false);
      }, 400);
      setShowContent(false);
      return;
    }
    setTimeout(() => {
      setShowContent(true);
    }, 800);
    setShowFab(true);
  }, [show]);

  return (
    <WidgetContext.Provider value={context}>
      <WidgetController />
      <WidgetContainer>
        <Content />
      </WidgetContainer>
    </WidgetContext.Provider>
  );
};

export default WidgetInstance;
