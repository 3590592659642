import React, { createContext, useContext, useEffect, useState } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import pull from 'lodash/pull';
export const SearchContext = createContext({
  selectedGroup: null,
  setSelectedGroup: (value) => null,
  searchGroups: [],
  refineValue: []
});

const SearchProvider = ({ children }) => {
  const [refineValue, setRefineValue] = useState([]);
  const [searchGroups, setSearchGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const {
    prismic: {
      allSearch_groups: searchGroupsPrismic,
      allSearch_categorys: categoriesPrismic
    }
  } = useContext(PageContext);

  useEffect(() => {
    const newSearchGroups = getSearchGroups();
    if (!newSearchGroups) {
      return;
    }
    setSearchGroups(newSearchGroups);
    setSelectedGroup(newSearchGroups[0]);
  }, []);

  function getSearchGroups() {
    if (!searchGroupsPrismic) {
      return;
    }
    const newSearchGroups = searchGroupsPrismic.edges.map(
      (searchGroupPrismic) => {
        const {
          node: {
            _meta: { uid }
          }
        } = searchGroupPrismic;
        const categories = categoriesPrismic.edges
          .filter((categoryPrismic) => {
            const {
              node: { search_group }
            } = categoryPrismic;
            if (!search_group) {
              return;
            }
            const {
              _meta: { uid: groupUid }
            } = search_group;
            return groupUid == uid;
          })
          .map((categoryPrismic) => {
            const {
              node: {
                _meta: { uid }
              }
            } = categoryPrismic;
            return {
              uid,
              ...categoryPrismic.node
            };
          });

        const newSearchGroup = {
          ...searchGroupPrismic.node,
          categories,
          refineValue: [],
          refine: (value) => {
            const newValue = [...value];
            pull(
              newValue,
              newSearchGroup.categories.map(({ uid }) => uid)
            );
            Object.assign(newSearchGroup, {
              refineValue: newValue
            });
            setSearchGroups(newSearchGroups);
            setRefineValue(newValue);
          },
          uid
        };
        return newSearchGroup;
      }
    );
    return newSearchGroups;
  }
  return (
    <SearchContext.Provider
      value={{ refineValue, searchGroups, selectedGroup, setSelectedGroup }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
