import React from 'react';
import Image from '@leuven2030/framework/Image/Image';
import 'twin.macro';
import ImageZoom from '@leuven2030/framework/Image/ImageZoom';
const PrismicImage = ({ element }) => {
  const height = 320;
  return (
    <div tw="flex justify-center">
      <ImageZoom>
        <div tw="w-full h-full flex items-center justify-center mx-auto">
          <Image
            src={element.url}
            width={800}
            height={height}
            imgixParams={{
              fit: 'clip'
            }}
            css={{
              maxHeight: height,
              width: 'auto'
            }}
            tw="overflow-hidden shadow-lg rounded-lg mx-auto"
          />
        </div>
      </ImageZoom>
    </div>
  );
};

export default PrismicImage;
