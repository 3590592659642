import { InstantSearch, Configure } from 'react-instantsearch-dom';
import React from 'react';
import Menu from '@leuven2030/framework/Menu/Menu';
import 'twin.macro';
import SearchAlgoliaLite from '@leuven2030/framework/Search/SearchAlgoliaLite';
import useBreakpoint from '@leuven2030/framework/hooks/useBreakpoint';
import SearchInput from '@leuven2030/framework/Search/SearchInput';
import SearchHits from '@leuven2030/framework/Search/SearchHits';
import SearchMenuTrigger from '@leuven2030/framework/Search/SearchMenuTrigger';
import SearchFilters from '@leuven2030/framework/Search/SearchFilters';
import SearchAlgoliaCredit from '@leuven2030/framework/Search/SearchAlgoliaCredit';
import SearchGroups from '@leuven2030/framework/Search/SearchGroups';
import SearchProvider from './SearchProvider';
import SearchState from '@leuven2030/framework/Search/SearchState';

const SearchMenu = ({ triggerProps }) => {
  const smBreakpoint = useBreakpoint();

  return (
    <SearchProvider>
      <InstantSearch
        indexName={process.env.ALGOLIA_INDEX}
        searchClient={SearchAlgoliaLite}
      >
        <Menu
          triggerComponent={<SearchMenuTrigger {...triggerProps} />}
          mobileConfig={{ fillHeight: true }}
        >
          <div
            tw="bg-gray-50 sm:max-w-xl xl:max-w-3xl sm:w-screen flex flex-col overflow-hidden"
            css={{ minWidth: 300, minHeight: 300 }}
          >
            {smBreakpoint && <SearchInput autoFocus />}
            <SearchGroups tw=" px-6 border-b bg-white" attribute="group" />
            <div tw="flex flex-col md:flex-row flex-1">
              {!smBreakpoint && (
                <SearchFilters
                  tw="bg-gray-100"
                  css={{ width: 200 }}
                  attribute="category"
                />
              )}
              <div tw=" space-y-3 flex-1 flex flex-col justify-between">
                <div>
                  <SearchState />
                  <SearchHits />
                </div>
                <div tw="flex justify-end px-3 pb-3">
                  <SearchAlgoliaCredit />
                </div>
              </div>
            </div>
          </div>
        </Menu>
        <Configure hitsPerPage={6} />
      </InstantSearch>
    </SearchProvider>
  );
};

export default SearchMenu;
