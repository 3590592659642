import React, { forwardRef, InputHTMLAttributes } from 'react';
import tw from 'twin.macro';

export const InputStyle = tw`block w-full border border-gray-300 rounded-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 sm:text-sm sm:leading-5 p-3 leading-normal`;

const FieldInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & { inputRef?: any }
>(function Input({ inputRef, ...props }, ref) {
  return (
    <input
      ref={inputRef || ref}
      {...props}
      css={InputStyle}
      autoComplete="off"
      autoCapitalize="off"
    />
  );
});

export default FieldInput;
