import { createContext } from 'react';
import User from '@leuven2030/framework/Auth/User';

export type UserContextProps = {
  user?: User;
  isLoggedin: boolean;
  reloadUserInfo: () => Promise<any>;
  signOut: () => void;
  signIn: () => void;
  signUp: () => void;
  currentUser?: any;
};

const UserContext = createContext<UserContextProps>({} as any);

export default UserContext;
