import ListItemMaterial from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Radio from '@material-ui/core/Radio';
import React, { FC } from 'react';
import tw from 'twin.macro';
import { css } from '@emotion/css';

type Props = {
  selected?: boolean;
  primary: string;
  secondary?: string;
  imageSrc?: string;
  Icon?: FC;
  onClick?: () => void;
};
const GroupListItem: FC<Props> = ({
  selected,
  primary,
  secondary,
  imageSrc,
  Icon,
  onClick
}) => {
  return (
    <ListItemMaterial
      ContainerComponent="div"
      ContainerProps={{
        className: css`
          ${tw`w-full`}
        `
      }}
      tw="py-3 w-full"
      button={true}
      onClick={onClick}
    >
      {imageSrc && (
        <ListItemAvatar tw="mr-4">
          <img tw="w-16 h-16" src={imageSrc} />
        </ListItemAvatar>
      )}
      {Icon && (
        <ListItemAvatar tw="mr-4">
          <div tw="w-16 h-16 flex items-center justify-center bg-gray-200 rounded-sm ">
            <Icon tw="w-8 h-8 text-gray-600" />
          </div>
        </ListItemAvatar>
      )}
      <ListItemText tw="pr-4" primary={primary} secondary={secondary} />
      <ListItemSecondaryAction>
        <Radio
          disabled={!selected}
          checked={selected}
          color="primary"
          disableRipple
        />
      </ListItemSecondaryAction>
    </ListItemMaterial>
  );
};

export default GroupListItem;
