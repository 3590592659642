import { useFormikContext } from 'formik';
import get from 'lodash/get';
import 'twin.macro';
import React, { FC } from 'react';
import FieldAutocomplete, {
  FieldAutocompleteProps
} from '@leuven2030/framework/Field/FieldAutocomplete';
import FieldProvider, { FieldProps } from '@leuven2030/framework/Field/FieldProvider';
import FormFieldAutocompleteTrigger from '@leuven2030/framework/Form/FormFieldAutocompleteTrigger';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export type FormFieldAutocompleteProps = FieldProps & {
  Component?: FC;
  autocompleteProps: FieldAutocompleteProps;
};
const FormFieldAutocomplete: FC<FormFieldAutocompleteProps> = ({
  autocompleteProps,
  Component,
  className,
  ...fieldProps
}) => {
  const { setFieldValue } = useFormikContext();
  const { name } = fieldProps;
  const AutocompleteComponent = Component || FieldAutocomplete;
  return (
    <FieldProvider
      {...fieldProps}
      inputIcon={<ArrowDropDownIcon tw="h-5 w-5 text-gray-400" />}
    >
      <AutocompleteComponent
        className={className}
        onSelected={(value) => {
          // TODO: 'set value'
          name && setFieldValue(name, get(value, 'id'));
        }}
        TriggerComponent={FormFieldAutocompleteTrigger}
        {...autocompleteProps}
      />
    </FieldProvider>
  );
};

export default FormFieldAutocomplete;
