import tw from 'twin.macro';
import { css } from '@emotion/react';

export const StyleContent = tw`max-w-3xl px-3 sm:px-6 lg:max-w-3xl xl:max-w-4xl 2xl:max-w-5xl mx-auto w-full`;

export const StyleParallaxCover = css`
  img {
    object-fit: cover;
  }
`;
