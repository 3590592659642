import { Formik, Form as FormikForm, FormikValues, FormikConfig } from 'formik';
import React, { createContext, FC, useEffect, useState } from 'react';
import 'twin.macro';
import FormSchema from '@leuven2030/framework/Form/FormSchema';
import FocusLock from 'react-focus-lock';

type FormContextProps = {
  recaptcha: FormRecaptchaOptions;
  loading: boolean;
  validationSchema: any;
  setValidationSchema: (schema) => any;
  error: any;
  successState?: any;
  setError: (error) => any;
  submitted: boolean;
  setSubmitted: (value) => any;
  setLoading: (value) => any;
  showSuccessIndicators: boolean;
};
export const FormContext = createContext<FormContextProps>({
  recaptcha: null,
  loading: false,
  validationSchema: null,
  setValidationSchema: (schema) => null,
  error: null,
  setError: (error) => null,
  submitted: false,
  setSubmitted: (value) => null,
  setLoading: (value) => null,
  showSuccessIndicators: false
});

export type FormProps = {
  className?: string;
  schemaName?: string;
  recaptcha?: FormRecaptchaOptions;
  showSuccessIndicators?: boolean;
  successState?: any;
} & FormikConfig<FormikValues>;

export type FormRecaptchaOptions = {
  action: string;
  text: any;
};

const Form: FC<FormProps> = ({
  className,
  recaptcha,
  showSuccessIndicators,
  schemaName,
  children,
  successState,
  validationSchema: initialValidationSchema,
  ...rest
}) => {
  const [validationSchema, setValidationSchema] = useState(
    initialValidationSchema
  );
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setValidationSchema(initialValidationSchema);
  }, [initialValidationSchema]);

  const formContext: FormContextProps = {
    error,
    showSuccessIndicators,
    successState,
    recaptcha,
    loading,
    validationSchema,
    setValidationSchema,
    setError,
    setLoading,
    submitted,
    setSubmitted
  };

  return (
    <FormContext.Provider value={formContext}>
      <Formik validationSchema={validationSchema} {...rest} validateOnMount>
        <FormikForm autoComplete="off">
          <FocusLock className={className}>{children}</FocusLock>
          {schemaName && <FormSchema name={schemaName} />}
        </FormikForm>
      </Formik>
    </FormContext.Provider>
  );
};

export default Form;
