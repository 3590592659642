import Slide from '@material-ui/core/Slide';
import ButtonBottom from '@leuven2030/framework/Button/ButtonBottom';
import CloseIcon from '@material-ui/icons/Close';
import React, { useContext, useEffect, useState } from 'react';
import { MenuMobileContext } from '@leuven2030/framework/Menu/MenuMobileProvider';
import tw from 'twin.macro';
import Portal from '@material-ui/core/Portal';
import useBodyLock from '@leuven2030/framework/hooks/useBodyLock';
import Backdrop from '@material-ui/core/Backdrop';
import { useRouter } from 'next/router';
import { MenuContext } from '@leuven2030/framework/Menu/Menu';

const MenuMobilePortal = (props) => {
  const { open } = useContext(MenuMobileContext);
  return <>{open && <MenuMobileContent {...props} />}</>;
};

const MenuMobileContent = ({ children }) => {
  const router = useRouter();
  const { show, onClose } = useContext(MenuMobileContext);
  const {
    mobileConfig: { fillHeight }
  } = useContext(MenuContext);
  const [initialRouterPath] = useState(router.asPath);
  useEffect(() => {
    if (initialRouterPath == router.asPath) {
      return;
    }
    onClose();
  }, [router.asPath]);
  useBodyLock();

  return (
    <Portal>
      <div tw="fixed z-20 w-screen top-0 left-0 h-screen">
        <Backdrop open={show} onClick={onClose} />
        <div tw="pt-4 h-full px-3 pointer-events-none">
          <Slide direction="up" in={show} appear mountOnEnter unmountOnExit>
            <div tw="w-full h-full  flex flex-col justify-end max-w-lg mx-auto ">
              <div
                tw="rounded-tl rounded-tr pointer-events-auto bg-white overflow-y-auto pb-16 sm:pb-0"
                css={[fillHeight && tw`h-full`]}
              >
                {children}
              </div>
            </div>
          </Slide>
        </div>
        {show && <ButtonBottom onClick={onClose} icon={<CloseIcon />} />}
      </div>
    </Portal>
  );
};

export default MenuMobilePortal;
