import State, { StateProps } from '@leuven2030/framework/State/State';
import { FC } from 'react';

const StateNotFound: FC<StateProps> = (props) => {
  return (
    <State
      filename="ed0d6daa-3c92-4d2d-9637-a901806fca90_state+-+not+found.png"
      {...props}
    />
  );
};

export default StateNotFound;
