import FieldText, {
  FieldTextProps
} from '@leuven2030/framework/Field/FieldText';
import React, { FC } from 'react';
import isUndefined from 'lodash/isUndefined';
const FieldEmail: FC<FieldTextProps> = ({ title, name, ...rest }) => {
  return (
    <FieldText
      {...rest}
      name={name || 'email'}
      type="email"
      title={isUndefined(title) ? 'Email' : title}
    />
  );
};

export default FieldEmail;
