import { InputBaseProps } from 'formik-material-ui';
import React, { FC } from 'react';
import 'twin.macro';
import FieldContainer from '@leuven2030/framework/Field/FieldContainer';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Field from '@leuven2030/framework/Field/Field';
import { FieldProps } from '@leuven2030/framework/Field/FieldProvider';
import { InputStyle } from '@leuven2030/framework/Field/FieldInput';

const FieldComponent: FC<InputBaseProps> = ({ className, ...props }) => {
  const containerProps = { className };
  const { field } = props;

  return (
    <FieldContainer {...containerProps}>
      <TextareaAutosize
        css={InputStyle}
        rowsMin={4}
        rowsMax={8}
        aria-label="maximum height"
        placeholder="Maximum 4 rows"
        {...field}
        {...props}
      />
    </FieldContainer>
  );
};

const FieldTextArea: FC<FieldProps> = (props) => {
  return <Field component={FieldComponent} {...props} />;
};

export default FieldTextArea;
