import { InputBase, InputBaseProps } from 'formik-material-ui';
import InputBaseMaterial from '@material-ui/core/InputBase';
import React, { FC, useContext } from 'react';
import 'twin.macro';
import FieldContainer from '@leuven2030/framework/Field/FieldContainer';
import FieldInputWithIcons from '@leuven2030/framework/Field/FieldInputWithIcons';
import Field from '@leuven2030/framework/Field/Field';
import FieldContainerBase from '@leuven2030/framework/Field/FieldContainerBase';
import {
  FieldContext,
  FieldProps
} from '@leuven2030/framework/Field/FieldProvider';
import FieldInput from '@leuven2030/framework/Field/FieldInput';

const FieldComponent: FC<InputBaseProps> = ({
  className,
  inputIcon: inputIconProps,
  ...props
}) => {
  const { name, inputIcon: inputIconContext } = useContext(FieldContext);
  const inputIcon = inputIconProps || inputIconContext;
  const containerProps = { className };
  if (!name) {
    return (
      <FieldInputWithIcons icons={[inputIcon]} {...containerProps}>
        <InputBaseMaterial inputComponent={FieldInput} tw="w-full" {...props} />
      </FieldInputWithIcons>
    );
  }
  return (
    <FieldContainer {...containerProps}>
      <InputBase inputComponent={FieldInput} tw="w-full" {...props} />
    </FieldContainer>
  );
};

const FieldText: FC<FieldProps> = (props) => {
  if (props.readOnly) {
    return <FieldContainerBase {...props} />;
  }
  return <Field component={FieldComponent} {...props} />;
};

export default FieldText;
