import React, { useContext } from 'react';
import { FormContext } from '@leuven2030/framework/Form/Form';
import 'twin.macro';
import PrismicText from '@leuven2030/framework/Prismic/PrismicText';
const RecaptchaMessage = () => {
  const { recaptcha } = useContext(FormContext);
  if (!recaptcha) {
    return <></>;
  }
  return (
    <div tw="text-gray-500 mt-2 text-xs">
      <PrismicText text={recaptcha.text} />
    </div>
  );
};

export default RecaptchaMessage;
