import React, { useContext } from 'react';
import { SearchContext } from '@leuven2030/framework/Search/SearchProvider';
import get from 'lodash/get';
import 'twin.macro';
import PrismicText from '@leuven2030/framework/Prismic/PrismicText';
import Image from '@leuven2030/framework/Image/Image';
import NavigationLink from '@leuven2030/framework/Navigation/NavigationLink';
import ImageBackground from '@leuven2030/framework/Image/ImageBackground';
const SearchState = () => {
  const { selectedGroup } = useContext(SearchContext);

  function getContent() {
    if (!get(selectedGroup, 'state')) {
      return;
    }
    const {
      state: { title, text, image, action_label, action_link }
    } = selectedGroup;

    return (
      <div tw="flex flex-col md:(flex-row) items-stretch bg-gray-100">
        <div tw="p-4 space-y-3 flex-1">
          <h3 tw="text-2xl text-primary-500 ">{title}</h3>
          <PrismicText text={text} />
          <NavigationLink prismicMeta={action_link} tw="text-primary-600">
            {action_label}
          </NavigationLink>
        </div>
        <ImageBackground src={image.url} width={400} height={500} tw="flex-1" />
      </div>
    );
  }

  return <>{getContent()}</>;
};

export default SearchState;
