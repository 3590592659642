import NavigationMenuItem from '@leuven2030/food/Navigation/NavigationMenuItem';
import NavigationMenuButton from '@leuven2030/food/Navigation/NavigationMenuButton';
import 'twin.macro';
import React, { useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import Menu from '@leuven2030/framework/Menu/Menu';
import PageLink from '@leuven2030/framework/Page/PageLink';
import NavigationMenuFooter from '@leuven2030/framework/Navigation/NavigationMenuFooter';

const NavigationMenu = ({ data, buttonProps = {} }) => {
  const {
    prismic: {
      layout: { read_more }
    }
  } = useContext(PageContext);
  const {
    title,
    items,
    disabled,
    footer,
    page,
    _meta: { uid }
  } = data;

  if (disabled) {
    return <></>;
  }

  console.log('items', items);

  return (
    <Menu
      triggerComponent={<NavigationMenuButton label={title} {...buttonProps} />}
    >
      <div tw="relative mx-auto bg-primary-500 pb-16 sm:pb-0">
        <div tw="space-y-6 bg-white">
          <div tw="flex items-center justify-between">
            {/* <h3 tw="text-sm leading-5 font-medium tracking-wide text-gray-500 uppercase">
              {title}
            </h3> */}
            <div tw="flex items-center">
              {page && <PageLink title={read_more} _meta={page._meta} />}
            </div>
          </div>
          <div tw="mt-0">
            <div tw="-mt-6">
              {items.map((item, index) => {
                if (!item.page) {
                  return;
                }
                return (
                  <NavigationMenuItem
                    key={index}
                    data={item}
                    first={index === 0}
                  />
                );
              })}
            </div>
          </div>
          <div tw="border-gray-100 border-t-2 p-6 -mt-6">
            <div tw="">
              {footer.map((item, index) => {
                if (!item.page) {
                  return;
                }
                return (
                  <NavigationMenuItem
                    key={index}
                    data={item}
                    tw="text-primary-400"
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Menu>
  );
};

export default NavigationMenu;
