import React, { createContext } from 'react';
import 'twin.macro';

export const FormStepContext = createContext({
  index: 0,
  label: '',
  fields: [],
  description: ''
});

const FormStepProvider = ({ label, description, index, fields, children }) => {
  const context = {
    index,
    label,
    fields,
    description
  };

  return (
    <FormStepContext.Provider value={context}>
      {children}
    </FormStepContext.Provider>
  );
};

export default FormStepProvider;
