import React, { FC, useState } from 'react';
import 'twin.macro';
import PopperMaterial from '@material-ui/core/Popper';
import ModalArrow from '@leuven2030/framework/Modal/ModalArrow';
import ModalStyle from '@leuven2030/framework/Modal/ModalStyle';
import { PopperProps } from '@material-ui/core/Popper/Popper';
import Grow from '@material-ui/core/Grow';

const ModalPopper: FC<
  PopperProps & {
    flip?: boolean;
  }
> = ({ children, flip, ...props }) => {
  const [arrowRef, setArrowRef] = useState(null);

  return (
    <PopperMaterial
      transition
      css={ModalStyle}
      modifiers={{
        arrow: {
          enabled: true,
          element: arrowRef
        },
        flip: {
          enabled: flip
        }
      }}
      {...props}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom'
          }}
        >
          <div>
            <div className="arrow" css={ModalArrow} ref={setArrowRef} />
            {children}
          </div>
        </Grow>
      )}
    </PopperMaterial>
  );
};

export default ModalPopper;
