import Image from '@leuven2030/framework/Image/Image';
import 'twin.macro';
import React, { FC, ReactNode } from 'react';

export type StateProps = {
  url?: string;
  title?: string;
  filename?: string;
  imageSize?: number;
  description?: string | ReactNode;
};

const defaultImage =
  'b83aa546-f8be-4424-85e3-b67fd05a9ec5_state+-+all+done.png';

const State: FC<StateProps> = ({
  children,
  filename,
  url,
  imageSize: imageSizeProp,
  title,
  description
}) => {
  url = url || getUrl(filename) || getUrl(defaultImage);

  function getUrl(filename) {
    return filename
      ? `https://images.prismic.io/school-leuven2030/${filename}`
      : null;
  }
  const imageSize = imageSizeProp || 300;
  return (
    <div tw="w-full flex flex-col items-center space-y-6">
      <Image
        src={url}
        width={imageSize}
        height={imageSize}
        imgixParams={{
          fit: 'fill',
          fill: 'solid',
          'max-w': imageSize,
          'max-h': imageSize
        }}
      />
      <div tw="text-center w-full max-w-md mx-auto space-y-1">
        <p tw="text-xl text-primary-600">{title}</p>
        <p tw="text-gray-500">{description}</p>
      </div>
      {children}
    </div>
  );
};

export default State;
