import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import React, { createContext, FC, useCallback, useState } from 'react';

type Props = {};

export const ImageZoomContext = createContext({
  isZoomed: false
});
const ImageZoom: FC<Props> = ({ children, ...rest }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const handleZoomChange = useCallback(
    (shouldZoom) => {
      setIsZoomed(shouldZoom);
    },
    [isZoomed]
  );

  const context = {
    isZoomed
  };

  return (
    <ImageZoomContext.Provider value={context}>
      <ControlledZoom
        isZoomed={isZoomed}
        onZoomChange={handleZoomChange}
        zoomMargin={20}
        overlayBgColorEnd="rgba(0, 0, 0, 0.3)"
        overlayBgColorStart="rgba(0, 0, 0, 0)"
        {...rest}
      >
        {children}
      </ControlledZoom>
    </ImageZoomContext.Provider>
  );
};

export default ImageZoom;
